//-------------------------------------
// applicationページのスタイル
//-------------------------------------
#application{
  .mv{
    background-image: url(images/application/application_mv-bg.jpg);
  }
  .message-box{
    &:before{
      background-image: url(images/application/application_message-ph.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
    min-width: 1200px;
    @include mq(sp){
      min-width: 0;
    }
  }
  .application{
    @include mq(sp){
      margin-top: 40px;
      padding-bottom: 40px;
    }
    &__box{
      padding: 80px 0 30px;
      background-color: #fff;
      @include mq(sp){
        padding: 30px 10px 20px;
      }
    }
    &__copy{
      font-size: 3rem;
      line-height: 3rem;
      text-align: center;
      color: #69829b;
      position: relative;
      letter-spacing: .1em;
      padding: 20px 0 40px;
      &:after{
        content: '';
        display: block;
        width: 100px;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 18px auto 0;
        background-color: #69829b;
        @include mq(sp){
          margin-top: 10px;
        }
      }
      @include mq(sp){
        font-size: 2.3rem;
      }
    }
    &__desc{
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 25px;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
    .formtable{
      width: 100%;
      margin: 40px 0 30px;
      &__name{
        input[type="text"]{
          width: 35%;
          @include mq(sp){
            width: 70%;
          }
        }
      }
      &__phonetic{
        input[type="text"]{
          width: 35%;
          @include mq(sp){
            width: 70%;
          }
        }
      }
      &__email{
        input[type="text"]{
          width: 55%;
          @include mq(sp){
            width: 80%;
          }
        }
      }
      &__tel{
        input[type="text"]{
          width: 30%;
          @include mq(sp){
            width: 75%;
          }
        }
      }
      &__job{
        label{
          cursor: pointer;
          display: inline-block;
          position: relative;
          margin-right: 20px;
          transition: .3s ease;
          input[type="radio"]{
            &:checked + .lever{
              &:before{
                opacity: 0;
                background: #dce1eb;
                border-color: #dce1eb;
                transform:scale(2);
              }
              &:after{
                opacity: 1;
                position: absolute;
                left: 7px;
                top: 3px;
                width: 6px;
                height: 14px;
                border-right: 2px solid #69829b;
                border-bottom: 2px solid #69829b;
                transform: rotate(40deg);
              }
            }
          }
          .lever{
            font-size: 1.5rem;
            line-height: 1.5rem;
            &:before{
              content:'';
              border: 1px solid #69829b;
              width: 16px;
              height: 16px;
              margin-right: 8px;
              display: inline-block;
              vertical-align: middle;
              border-radius: 50%;
              transition: .4s ease;
            }
            &:after{
              content:'';
              opacity: 0;
              position: absolute;
              left: 8px;
              top: 0px;
              width: 3px;
              height: 7px;
              border-right: 2px solid #fff;
              border-bottom: 2px solid #fff;
              transform:rotate(-310deg);
              transition: .15s ease-out;
            }
          }
        }
      }
      &__post{
        input[type="text"]{
          width: 30%;
          @include mq(sp){
            width: 70%;
          }
        }
      }
      &__address{
        input[type="text"]{
          width: 80%;
          @include mq(sp){
            width: 100%;
          }
        }
      }
    }
    tr{
      border-bottom: 1px solid #ddd;
      &:first-child{
        border-top: 1px solid #ddd;
      }
    }
    th{
      text-align: left;
      font-size: 1.6rem;
      font-weight: 500;
      width: 200px;
      padding: 30px 0;
      vertical-align: top;
      @include mq(sp){
        width: 100%;
        display: block;
        font-size: 1.4rem;
        padding: 20px 0 0;
      }
    }
    td{
      padding: 30px 0 30px 30px;
      font-size: 1.6rem;
      @include mq(sp){
        width: 100%;
        display: block;
        padding: 10px 0 20px;
        font-size: 1.4rem;
      }
    }
    input[type="text"]{
      background-color: #fff;
      border: 1px solid #91a5be;
      padding: 10px;
      width: 100%;
      &:focus{
        box-shadow: 0 0 8px rgba(105,130,155,.3);
      }
    }
    textarea{
      background-color: #fff;
      border: 1px solid #91a5be;
      padding: 10px;
      width: 100% !important;
      height: 160px !important;
      &:focus{
        box-shadow: 0 0 8px rgba(105,130,155,.3);
      }
    }
    .border-btn{
      border-color: #004bb4;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        display: block;
        width: 100%;
        height: 100%;
      }
      input{
        font-size: 1.8rem;
        line-height: 1.8rem;
        width: 100%;
        height: 100%;
        display: block;
        color: #fff;
        background-color: #004bb4;
        cursor: pointer;
        transition: .3s ease;
        @include mq(sp){
          font-size: 1.4rem;
        }
        &:hover{
          background-color: transparent;
          color: #004bb4;
        }
      }
    }
  }
}