//-------------------------------------
// TOPページのスタイル
//-------------------------------------
#Top{
  .mv{
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(images/top/top_mv-loading.jpg) 50% 50% no-repeat;
      background-size: cover;
      animation: loading 1s ease 2s;
      animation-fill-mode:forwards;
      @include mq(sp){
        background-image: url(images/top/top_mv-loading_sp.jpg);
      }
    }
  }
}
.mv{
  background-position: center center;
  background-size: cover;
  min-width: 1200px;
  height: 760px;
  padding: 40px 0;
  text-align: center;
  color: #fff;
  @include mq(sp){
    min-width: 0;
    max-width: 100vw;
    min-height: 350px;
    max-height: 405px;
    height: 84vw;
    padding: 20px 0;
    background-size: auto 100%;
  }
  &__txt{
    background-color: rgba(40,55,70,.35);
    max-width: 1520px;
    margin: 0 auto;
    height: 100%;
    display: block;
    @include mq(sp){
      margin: 0 10px;
    }
  }
  &__title{
    padding-top: 180px;
    @include mq(sp){
      margin: 0 auto;
      padding: 40px 5vw 0;
      max-width: 462px;
      box-sizing: content-box;
    }
  }
  &__copy{
    font-size: 4.2rem;
    font-weight: 400;
    padding-top: 50px;
    @include mq(sp){
      font-size: 2.1rem;
      line-height: 1rem;
      padding-top: 28px;
    }
  }
  &__desc{
    font-size: 1.8rem;
    line-height: 3.4rem;
    font-weight: 200;
    padding: 8px 0 50px;
    @include mq(sp){
      font-size: 1.2rem;
      line-height: 2.1rem;
      padding: 17px 0;
    }
  }
}
.maincontent{
  position: relative;
  background-color: #fff;
  margin: 90px 0 0 0;
  position: relative;
  @include mq(sp){
    margin: 45px 10px 0;
  }
}
.tab-btn{
  position: absolute;
  top: -50px;
  width: 100%;
  padding: 0 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  z-index: 20;
  @include mq(sp){
    padding: 0 10px;
    top: -25px;
  }
  .active{
    background-color: #004bb4;
    cursor: default;
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 18px 0 18px;
      border-color: #004bb4 transparent transparent transparent;
      position: absolute;
      bottom: -24px;
      left: 0;
      right: 0;
      margin: auto;
      @include mq(sp){
        border-width: 12px 10px 0 10px;
        bottom: -12px;
      }
    }
  }
  >div{
    width: 49%;
    background-color: #91a5be;
    color: #fff;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s ease;
    @include mq(sp){
      height: 50px;
    }
    &:hover{
      background-color: #004bb4;
    }
    p{
      font-size: 2.8rem;
      position: relative;
      width: 100%;
      @include mq(sp){
        font-size: 1.4rem;
      }
      &:before{
        content: '';
        display: block;
        width: 40px;
        height: 50px;
        position: absolute;
        left: 50px;
        top: 0;
        bottom: 0;
        margin: auto;
        @include mq(sp){
          background-size: 100%;
          width: 20px;
          height: 25px;
          left: calc(2% + 5px);
        }
      }
    }
  }
  &__overview{
    p{
      &:before{
        background: url(images/common/icon_company.png) center 0 no-repeat;
      }
    }
  }
  &__recruit{
    p{
      &:before{
        background: url(images/common/icon_human.png) center 0 no-repeat;
      }
    }
  }
}
#slide-content{
  position: relative;
  z-index: 10;
}
.overview-grp{
  width: 100%;
  padding-top: 100px;
  text-align: center;
  transform-origin: center center -50vw; 
  transition: all 1s ease 0s;
  top: 0;
  z-index: 10;
  @include mq(sp){
    padding-top: 55px;
  }
  h2{
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 500;
    @include mq(sp){
      font-size: 2.1rem;
      line-height: 2.6rem;
    }
  }
  h3{
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin: 15px 0 50px;
    @include mq(sp){
      font-size: 1.1rem;
      letter-spacing: .08rem;
      margin: 10px 0 15px;
    }
  }
  .item-box{
    display: flex;
    @include mq(sp){
      display: block;
    }
  }
  .box{
    width: 50%;
    min-height: 380px;
    color: #fff;
    padding: 30px;
    @include mq(sp){
      width: 100%;
      min-height: 0;
      height: 185px;
      padding: 10px;
    }
    &__txt{
      background-color: rgba(40,55,70,.4);
      height: 100%;
      padding-top: 75px;
      position: relative;
      @include mq(sp){
        padding-top: 25px;
      }
    }
    &__title{
      font-size: 3.6rem;
      line-height: 4.2rem;
      @include mq(sp){
        font-size: 2.1rem;
        line-height: 2.1rem;
      }
    }
    &__copy{
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 15px 0 38px;
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding: 10px 0 20px;
      }
    }
    .border-btn{
      width: 180px;
      height: 50px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 50px;
      @include mq(sp){
        width: 140px;
        height: 40px;
        bottom: 20px;
      }
      p{
        font-size: 1.4rem;
        @include mq(sp){
          font-size: 1.2rem;
        }
      }
    }
  }
  .box1{
    background: url(images/top/top_concept-bg.jpg) center 0 no-repeat;
    background-size: cover;
  }
  .box2{
    background: url(images/top/top_catalog-bg.jpg) center 0 no-repeat;
    background-size: cover;
  }
  .company{
    &__list{
      background-color: #f0f2f6;
      display: flex;
      justify-content: space-between;
      padding: 30px;
      @include mq(sp){
        display: block;
        padding: 20px;
      }
    }
    &__box{
      width: 32%;
      padding-bottom: 16px;
      @include mq(sp){
        width: 100%;
      }
    }
    &__logo{
      width: 100%;
      padding: 30px 0;
      background-color: #fff;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #d2dce6;
      display: block;
      &:hover{
        img{
          opacity: .5;
        }
      }
      @include mq(sp){
        padding: 15px 0;
      }
      img{
        height: 40px;
        transition: .3s ease;
        @include mq(sp){
          height: 30px;
        }
      }
    }
    &__title{
      font-size: 2rem;
      line-height: 3.2rem;
      font-weight: 500;
      padding-top: 15px;
      @include mq(sp){
        font-size: 1.6rem;
        line-height: 2.1rem;
        padding-top: 10px;
      }
    }
    &__copy{
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding-top: 5px;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
  }
  .kuroko{
    background-color: #fff;
    padding: 30px;
    height: 400px;
    @include mq(sp){
      height: auto;
      padding: 20px;
    }
    &__box{
      width: 100%;
      height: 100%;
      background-color: #f5f0eb;
      padding-top: 65px;
      padding-left: calc(100% - 400px);
      position: relative;
      @include mq(sp){
        padding: 0;
      }
      &:before{
        content: '';
        display: block;
        width: calc(100% - 400px);
        height: 100%;
        background: url(images/top/top_kuroko-bg.jpg) 40% 50% no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        @include mq(sp){
          position: static;
          height: 180px;
          width: auto;
          background-size: cover;
          background-position: top center;
        }
      }
      .border-btn{
        border-color: #000;
        width: 180px;
        height: 50px;
        @include mq(sp){
          width: 140px;
          height: 40px;
          margin: 0 auto;
        }
        a{
          &:hover{
            background-color: #2b2b2b;
            p{
              color: #f5f0eb;
            }
          }
        }
        p{
          color: #000;
          font-size: 1.4rem;
          @include mq(sp){
            font-size: 1.2rem;
          }
        }
      }
    }
    &__inner{
      @include mq(sp){
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
      }
    }
    &__logo{
      max-width: 340px;
      margin: 0 auto;
      a{
        &:hover{
          opacity: .7;
        }
      }
      @include mq(sp){
        padding: 0 20px 15px 20px;
        width: 100%;
        max-width: 380px;
      }
    }
    &__txt{
      @include mq(sp){
      }
    }
    &__title{
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 30px 0 22px;
      @include mq(sp){
        font-size: 1.2rem;
        margin: 0 0 10px 0;
      }
    }
  }
}
.recruit-grp{
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-align: center;
  transform-origin: center center -50vw; 
  transition: all 1s ease 0s;
  transform: matrix3d(0,0,1.00,0,0.00,1,0.00,0,-1,0,0,0,0,0,-100,1);
  z-index: 10;
  @include mq(sp){
    padding-top: 55px;
  }
  h2{
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 500;
    @include mq(sp){
      font-size: 2.1rem;
      line-height: 2.6rem;
    }
  }
  h3{
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin: 15px 0 50px;
    @include mq(sp){
      font-size: 1.1rem;
      line-height: 2rem;
      margin: 10px 0 15px;
    }
  }
  .item-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
    background-color: #f0f2f6;
    @include mq(sp){
      padding-bottom: 0;
    }
  }
  .box{
    width: 46.5%;
    min-height: 380px;
    color: #fff;
    padding: 30px;
    @include mq(sp){
      width: 100%;
      min-height: 185px;
      margin-bottom: 0;
      padding: 15px;
    }
    &__txt{
      background-color: rgba(40,55,70,.35);
      height: 100%;
      padding-top: 90px;
      @include mq(sp){
        padding: 30px 0 20px;
      }
    }
    &__title{
      font-size: 3.6rem;
      line-height: 4.2rem;
      @include mq(sp){
        font-size: 2.1rem;
        line-height: 2.1rem;
      }
    }
    &__copy{
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 15px 0 38px;
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 2.1rem;
        padding: 15px 0 18px;
      }
    }
    .border-btn{
      width: 180px;
      height: 50px;
      @include mq(sp){
        width: 140px;
        height: 40px;
      }
      p{
        font-size: 1.4rem;
        @include mq(sp){
          font-size: 1.2rem;
        }
      }
    }
  }
  .box1{
    width: 100%;
    min-height: 520px;
    background: url(images/top/top_recruit-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    @include mq(sp){
      margin-bottom: 0;
      min-height: 185px;
    }
    .box__title{
      font-size: 4.6rem;
      line-height: 4.2rem;
      padding-top: 50px;
      @include mq(sp){
        font-size: 2.1rem;
        line-height: 2.1rem;
        padding-top: 0;
      }
    }
    .box__copy{
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding: 15px 0 38px;
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 2.1rem;
        padding: 15px 0 18px;
      }
    }
    .border-btn{
      width: 240px;
      height: 60px;
      @include mq(sp){
        width: 140px;
        height: 40px;
      }
      p{
        font-style: 1.6rem;
        @include mq(sp){
          font-size: 1.2rem;
        }
      }
    }
  }
  .box2{
    background: url(images/top/top_guidelines-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-left: 30px;
    @include mq(sp){
      margin-left: 0;
    }
  }
  .box3{
    background: url(images/top/top_application-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-right: 30px;
    @include mq(sp){
      margin-right: 0;
    }
  }
}
.active-left{
  .overview-grp{
    transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,-100,1);
    position: static;
  }
  .recruit-grp{
    transform: matrix3d(0,0,-1.00,0,0.00,1,0.00,0,1,0,0,0,0,0,-100,1);
    position: absolute;
  }
}
.active-right{
  .overview-grp{
    transform: matrix3d(0,0,1.00,0,0.00,1,0.00,0,-1,0,0,0,0,0,-100,1);
    position: absolute;
  }
  .recruit-grp{
    transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,-100,1);
    position: static;
  }
}