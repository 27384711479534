/*--------------------------------------------------------------------------
 *   default;
 *---------------------------------------------------------------------------*/


/*--------------------------------------------------------------------------
 *	 html, body;
 *---------------------------------------------------------------------------*/
* {
  box-sizing: border-box;
}
html{
  font-size: 62.5% !important;
}
html, body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  letter-spacing: 1.2px;
  font-family: $noto;
  color: $color_text;
  line-height: 1.87;
}
body {
  background-color: #dce1eb;
  font-size: 1.6em;
  letter-spacing: .12em;
}


/*--------------------------------------------------------------------------
 *	 base link;
 *---------------------------------------------------------------------------*/
a {
  outline: none;
  opacity: 1;
  -webkit-tap-highlight-color: transparent;
  transition-duration: .3s;
  &:link, &:visited{
    text-decoration: none;
  }
  &:hover{
    text-decoration: none;
    outline: none;
  }
}

/* clear fix */
.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

/* img */
img{
  @include mq(sp){
    max-width: 100%;
  }
}


/*--------------------------------------------------------------------------
 *   form style;
 *---------------------------------------------------------------------------*/
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}