/*------------------------------
変数
-------------------------------*/
//色
$color_text: #000;
$main_color: #fbf4e8;



//breakpoint記述
//@include mq(sp){width:100%;}
$bp-sp: 750px !default;

@mixin mq($DEVICE) {
    @if $DEVICE == "pc" {
        @media (min-width: $bp-sp + 1) {
            @content;
        }
    }
    @else if $DEVICE == "sp" {
        @media (max-width: ($bp-sp)) {
            @content;
        }
    }
}



//font
$noto: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;






//表示・非表示切替
.object-pc{
  @include mq(sp){
    display: none;
  }
}
.object-sp{
  @include mq(pc){
    display: none;
  }
}

//animation
@keyframes loading {
  0% {opacity: 1;}
  99% {opacity: 0; z-index: 100}
  100% {opacity: 0; z-index: -9999;}
}