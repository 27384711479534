//-------------------------------------
// loddconページのスタイル
//-------------------------------------
#losscon{
  .mv{
    background-image: url(images/losscon/losscon_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .catalog{
    background-color: #d2dae6;
    padding: 80px 0;
    min-width: 1200px;
    @include mq(sp){
      padding: 30px 0;
      min-width: 0;
    }
    &__list-title{
      font-size: 2.8rem;
      line-height: 2.8rem;
      color: #69829b;
      @include mq(sp){
        font-size: 2.3rem;
        margin-left: 10px;
      }
    }
    &__list-subtitle{
      font-size: 1.6rem;
      line-height: 3rem;
      margin-top: 10px;
      color: #69829b;
      @include mq(sp){
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0;
      }
    }
    &__list{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      @include mq(sp){
        flex-direction: column;
      }
    }
    &__box{
      background-color: #fff;
      width: 285px;
      @include mq(sp){
        width: 100%;
        margin-top: 20px;
        &:first-child{
          margin-top: 0;
        }
      }
    }
    &__ph{
      @include mq(sp){
        img{
          width: 100%;
        }
      }
    }
    &__textarea{
      padding: 0 30px;
      @include mq(sp){
        padding: 0 20px;
      }
    }
    &__title{
      font-size: 1.8rem;
      line-height: 4.2rem;
      margin-top: 12px;
      @include mq(sp){
        font-size: 1.6rem;
      }
    }
    &__desc{
      font-size: 1.3rem;
      line-height: 2rem;
      color: #505050;
      min-height: 60px;
      @include mq(sp){
        font-size: 1.2rem;
        min-height: 0;
      }
    }
    &__btn{
      width: 100%;
      border-color: #004bb4;
      margin: 20px 0;
      @include mq(sp){
        margin: 15px auto;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #fff;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
    &__download{
      background-color: #f8f8f8;
      margin: 5px;
      border: 1px solid #eeeeee;
      a{
        padding: 10px;
        &:before{
          content: url(images/common/icon_pdf.png);
          display: inline-block;
          line-height: 0;
          vertical-align: middle;
        }
        &:hover{
          opacity: .7;
        }
        p{
          display: inline-block;
          font-size: 1.3rem;
          line-height: 4rem;
          color: #000;
          @include mq(sp){
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .sscenter{
    margin-top: 80px;
    .phbox{
      height: 380px;
      background: url(images/losscon/losscon_sscenter-bg.jpg) center 0 no-repeat;
      background-size: cover;
      color: #fff;
      letter-spacing: .08em;
      text-align: center;
      padding: 90px 300px 0;
      @include mq(sp){
        padding: 60px 10px;
        height: auto;
      }
      &__title{
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: #fff;
        width: 260px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 1px 0;
        border-style: solid;
        border-color: #fff;
        margin: 0 auto;
        @include mq(sp){
          width: 225px;
          font-size: 1.6rem;
          height: 50px;
        }
      }
      &__copy{
        font-size: 4rem;
        line-height: 4.6rem;
        margin: 50px 0 0;
        @include mq(sp){
          font-size: 2.1rem;
          margin: 15px 0 0;
        }
      }
      &__desc{
        font-size: 1.5rem;
        line-height: 3rem;
        font-weight: 400;
        @include mq(sp){
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }
    }
    .article{
      background-color: #fff;
      padding: 60px;
      display: flex;
      justify-content: space-between;
      @include mq(sp){
        flex-direction: column;
        padding: 30px 10px;
      }
      &__ph{
        @include mq(sp){
          width: 100%;
          max-width: 380px;
          margin: 0 auto;
          img{
            width: 100%;
          }
        }
      }
      &__textarea{
        width: 560px;
        padding: 25px 0 0;
        @include mq(sp){
          width: 100%;
          padding: 0;
        }
      }
      &__copy{
        font-size: 2.7rem;
        line-height: 3.8rem;
        font-weight: 500;
        @include mq(sp){
          font-size: 2.1rem;
          line-height: 3rem;
          max-width: 380px;
          margin: 20px auto;
        }
      }
      &__desc{
        font-size: 1.6rem;
        margin-top: 18px;
        @include mq(sp){
          font-size: 1.2rem;
          max-width: 380px;
          margin: 0 auto;
        }
      }
    }
  }
}