.l-inner {
  min-width: 1200px;
  max-width: 1520px;
  margin: 0 auto;
  @include mq(sp){
    min-width: 0;
    max-width: 730px;
  }
}
.lower{
  .maincontent{
    .l-inner{
      max-width: 1200px;
    }
  }
}

.l-content {
  padding-top: 90px;
  min-width: 1200px;
  @include mq(sp){
    padding-top: 55px;
    min-width: 0;
  }
}