//-------------------------------------
// justlogiページのスタイル
//-------------------------------------
#justlogi{
  .mv{
    background-image: url(images/justlogi/justlogi_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .message-box{
    &:before{
      background-image: url(images/justlogi/justlogi_message-ph1.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
    &__reverse{
      padding: 0 0 80px;
      margin: 80px 0 0 0;
      min-height: 280px;
      @include mq(sp){
        margin: 30px 0 0;
        padding: 0;
        min-height: 0;
      }
      .l-inner{
        position: relative;
      }
      .message-box__textarea{
        width: 500px;
        margin-left: auto;
        @include mq(sp){
          width: 100%;
          padding-top: 25px;
        }
      }
      .message-box__copy{
        padding-top: 0;
      }
      &:before{
        background-image: url(images/justlogi/justlogi_message-ph2.jpg);
        right: auto;
        left: 0;
        height: 380px;
      }
    }
  }
  .map-area{
    padding: 100px 0 100px 130px;
    background-color: #fff;
    position: relative;
    margin: 0 0 230px;
    @include mq(sp){
      padding: 50px 20px;
      margin: 0;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      width: 545px;
      height: 465px;
      background: url(images/justlogi/map.png) left top no-repeat;
      background-size: 100% auto;
      right: 40px;
      top: 40px;
      @include mq(sp){
        width: 75vw;
        height: 64.3vw;
        position: static;
        margin: auto;
        max-width: 420px;
        max-height: 359px;
      }
    }
    &__desc{
      font-size: 2.8rem;
      line-height: 4.6rem;
      @include mq(sp){
        font-size: 1.6rem;
        line-height: 1.6;
        margin: 30px auto 0;
        text-align: center;
      }
    }
  }
  .btn-list{
    background-color: #f0f2f6;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    @include mq(sp){
      flex-wrap: wrap;
      padding: 20px 0;
    }
    li{
      margin: 0 10px;
      @include mq(sp){
        margin: 5px;
      }
    }
    .border-btn{
      border-color: #004bb4;
      @include mq(sp){
        width: 280px;
        height: 60px;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #f0f2f6;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}