//---------------------------
// footerのスタイル
//---------------------------
.l-footer {
  padding-top: 100px;
  @include mq(sp){
    margin: 0 10px;
    padding-top: 40px;
  }
  .f-nav, .f-info{
    border-bottom: 1px solid #c3c8cd;
    padding: 0 10px;
    @include mq(sp){
      border: none;
      padding: 0;
      overflow: hidden;
      height: 0;
    }
  }
  .f-nav-btn, .f-info-btn{
    width: 100%;
    background-color: #91a5be;
    p{
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: #fff;
      text-align: center;
      padding: 18px 0;
      position: relative;
      &:after{
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background: url(images/common/plus.png) center 0 no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;
        margin: auto;
      }
    }
  }
  .f-nav-btn.opened, .f-info-btn.opened{
    p{
      &:after{
        background-image: url(images/common/minus.png);
      }
    }
  }
  .f-nav{
    border-top: 1px solid #c3c8cd;
    @extend .clearfix;
    @include mq(sp){
      border: none;
      margin-bottom: 20px;
    }
    &__title{
      font-size: 1.6rem;
      line-height: 4.2rem;
      letter-spacing: 0.12rem;
      color: #000;
      position: relative;
      padding-left: 25px;
      transition: .3s ease;
      &:hover{
        opacity: .7;
      }
      @include mq(sp){
        font-size: 1.4rem;
        border-top: 1px solid #c3c8cd;
      }
      &:before{
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #004bb4;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &:after{
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        border-color: #004bb4;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 5px;
        margin: auto;
      }
    }
    >li{
      float: left;
      width: 20%;
      min-height: 180px;
      margin: 30px 0;
      padding-left: 30px;
      border-left: 1px solid #c3c8cd;
      @include mq(sp){
        float: none;
        width: 100%;
        max-width: 375px;
        min-height: 0;
        margin: 0 auto;
        border-left: none;
        padding-left: 0;
      }
      &:first-child{
        border-left: none;
      }
      &:last-child{
        a{
          &:last-child{
            .f-nav__title{
              @include mq(sp){
                border-bottom: 1px solid #c3c8cd;
              }
            }
          }
        }
      }
    }
    ul{
      margin: 0 0 10px 20px;
      li{
        p{
          font-size: 1.3rem;
          line-height: 2.2rem;
          letter-spacing: 0.12rem;
          color: #000;
          position: relative;
          padding-left: 16px;
          transition: .3s ease;
          &:hover{
            opacity: .7;
          }
          @include mq(sp){
            font-size: 1.2rem;
          }
          &:before{
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            border-width: 1px 1px 0 0;
            border-style: solid;
            border-color: #004bb4;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .f-info{
    @extend .clearfix;
    >li{
      float: left;
      width: 20%;
      min-height: 180px;
      margin: 30px 0;
      padding-left: 35px;
      border-left: 1px solid #c3c8cd;
      @include mq(sp){
        float: none;
        width: 100%;
        max-width: 375px;
        min-height: 0;
        margin: 0 auto;
        border-left: none;
        padding: 10px 0 15px 25px;
        border-bottom: 1px solid #c3c8cd;
      }
      &:first-child{
        border-left: none;
      }
    }
    dt{
      font-size: 1.6rem;
      line-height: 4.2rem;
      letter-spacing: 0.12rem;
      color: #000;
      @include mq(sp){
        font-size: 1.4rem;
      }
    }
    dd{
      font-size: 1.3rem;
      line-height: 2.2rem;
      letter-spacing: 0;
      color: #000;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
  }
  .copyright{
    font-size: 1.1rem;
    line-height: 2.2rem;
    letter-spacing: .12rem;
    text-align: center;
    color: #000;
    margin: 55px 0;
    @include mq(sp){
      margin: 30px 0;
      font-size: 1rem;
      letter-spacing: .03rem;
    }
  }
}
.pagetop{
  width: 60px;
  height: 60px;
  background-color: rgba(83,108,138,.5);
  position: fixed;
  bottom: 72px;
  right: 0;
  z-index: 100;
  transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,100,1);
  transition: auto;
  cursor: pointer;
  display: none;
  @include mq(sp){
    width: 45px;
    height: 45px;
    right: 10px;
    bottom: 15px;
  }
  &:hover{
    background-color: rgba(45,70,100,1);
  }
  span{
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg) translate(15%,15%);
    width: 10px;
    height: 10px;
  }
}