/*--------------------------------
news-unit
-----------------------------------*/

.news-unit{
  background-color: #fff;
  margin: 45px auto;
  padding: 20px 50px 20px 0;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  @include mq(sp) {
    width: calc(100% - 20px);
    display: block;
    padding: 15px;
    margin: 32px auto;
  }
  &__label{
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: .12em;
    text-align: center;
    width: 260px;
    flex: 0 0 auto;
    @include mq(sp) {
      width: 100%;
      font-size: 2rem;
    }
  }
  &__list{
    width: 100%;
    li{
      width: 100%;
      &:nth-child(n+2){
        border-top: 1px solid #c3c8cd;
      }
    }
  }
  &__ttl{
    display: flex;
    font-size: 1.6rem;
    letter-spacing: .12em;
    color: $color_text;
    padding: .75em 1.5em .75em 0;
    position: relative;
    z-index: 0;
    cursor: pointer;
    transition: opacity .3s ease;
    @include mq(sp) {
      display: block;
      font-size: 1.4rem;
    }
    &:hover{
      opacity: .7;
    }
    &:before{
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #004bb4;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    &:after{
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: #004bb4;
      transform: rotate(135deg);
      position: absolute;
      top: 0;
      bottom: 2px;
      right: 6px;
      margin: auto;
    }
    &.-active{
      &:after{
        transform: rotate(-45deg);
        top: 2px;
        bottom: 0;
      }
    }
  }
  &__date{
    font-weight: 400;
    margin:  0 1em 0 0;
  }
  &__text{
    font-weight: 500;
  }
  &__content{
    display: none;
    padding: 1em;
    background-color: #f0f2f6;
    margin: 0 0 1em;
    p{
      font-size: 1.4rem;
      line-height: 1.8;
      @include mq(sp) {
        font-size: 1.3rem;
      }
      & + p{
        margin: 1em 0 0;
      }
    }
    a{
      text-decoration: underline;
      color: #004bb4;
      margin: 0 1em 0 0;
    }
  }
}