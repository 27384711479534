//-------------------------------------
// guidelineページのスタイル
//-------------------------------------
#guideline{
  .mv{
    background-image: url(images/guideline/guideline_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .guideline{
    background-color: #fff;
    padding: 60px;
    margin: 80px 0;
    @include mq(sp){
      padding: 20px;
      margin: 30px 0;
    }
    &__title{
      text-align: center;
      font-size: 2.8rem;
      line-height: 2.8rem;
      font-weight: 500;
      margin-bottom: 35px;
      @include mq(sp){
        font-size: 2.2rem;
        margin-bottom: 15px;
      }
    }
    table{
      width: 100%;
    }
    tr{
      width: 100%;
      padding: 18px 0;
      display: block;
      border-bottom: 1px solid #e0e0e0;
      @include mq(sp){
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px;
      }
      &:first-child{
        border-top: 1px solid #e0e0e0;
      }
    }
    th{
      width: 220px;
      font-weight: 500;
      font-size: 1.6rem;
      padding: 0 0 0 10px;
      vertical-align: top;
      text-align: left;
      line-height: 2.2rem;
      @include mq(sp){
        padding: 0;
        font-size: 1.4rem;
        flex: 0 0 auto;
      }
    }
    td{
      font-size: 1.4rem;
      line-height: 2.2rem;
      >ul{
        >li{
          margin-top: 6px;
          &:first-child{
            margin-top: 0;
          }
        }
      }
      ul{
        ul{
          font-size: 1.2rem;
          li{
            p{
              &:before{
                content: '･';
              }
            }
          }
        }
      }
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
  .flow{
    background-color: #d2dae6;
    padding: 80px 0;
    min-width: 1200px;
    @include mq(sp){
      padding: 30px 0 0;
      min-width: 0;
    }
    &__title{
      font-size: 2.8rem;
      line-height: 2.8rem;
      color: #69829b;
      @include mq(sp){
        font-size: 2.3rem;
        margin-left: 10px;
      }
    }
    &__subtitle{
      font-size: 1.6rem;
      line-height: 3rem;
      margin-top: 10px;
      color: #69829b;
      @include mq(sp){
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0;
      }
    }
    &__step-number{
      padding-bottom: 25px;
      @include mq(sp){
        padding-bottom: 20px;
      }
    }
    &__name{
      writing-mode: vertical-rl;
      font-size: 2.8rem;
      line-height: 3rem;
      color: #69829b;
      @include mq(sp){
        font-size: 2.6rem;
        writing-mode: horizontal-tb;
      }
    }
    ol{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      @include mq(sp){
        flex-direction: column;
      }
    }
    li{
      background-color: #fff;
      width: 100%;
      height: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      position: relative;
      @include mq(sp){
        height: 180px;
        padding-top: 45px;
      }
      &:first-child{
        &:before{
          content: none;
        }
      }
      &:before{
        content: '';
        display: block;
        background: url(images/guideline/whitearrow.png) left 0 no-repeat;
        background-size: cover;
        height: 100%;
        width: 50px;
        position: absolute;
        top: 0;
        left: -25px;
        @include mq(sp){
          background: url(images/guideline/whitearrow_sp.png) center 0 no-repeat;
          width: 100%;
          height: 87px;
          top: -70px;
          left: auto;
        }
      }
    }
  }
  .application-btn{
    background-color: #f0f2f6;
    margin-top: 80px;
    padding: 40px 0 50px;
    @include mq(sp){
      margin-top: 30px;
      padding: 25px 0;
    }
    &__title{
      font-size: 2.8rem;
      line-height: 2.8rem;
      text-align: center;
      margin-bottom: 22px;
      @include mq(sp){
        font-size: 1.8rem;
        margin-bottom: 8px;
      }
    }
    .border-btn{
      border-color: #004bb4;
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #fff;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}