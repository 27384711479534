//---------------------------
// headerのスタイル
//---------------------------
.l-header {
  background-color: #fff;
  height: 90px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,100,1);
  @include mq(sp){
    height: 55px;
  }
  .l-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 100%;
  }
  .logo{
    margin: 0 0 0 20px;
    @include mq(sp){
      margin: 0 0 0 10px;
    }
    img{
      height: 42px;
      @include mq(sp){
        height: auto;
        max-height: 30px;
      }
    }
  }
  .g-nav{
    display: flex;
    justify-content: space-between;
    height: 100%;
    .nav{
      @include mq(pc){
        li{
          transition: .3s ease;
          &:after{
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background-color: #004bb4;
            transition: .3s ease;
          }
          &:hover, &.active{
            p{
              color: #004bb4;
            }
            &:after{
              width: 100%;
            }
          }
        }
      }
    }
    ul{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 15px;
      @include mq(sp){
        display: block;
        transition: .4s ease;
      }
    }
    li{
      margin-right: 30px;
    }
    p{
      color: #000;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: .03rem;
      font-weight: 500;
    }
    .nav-btn{
      height: 100%;
      width: 110px;
      text-align: center;
      margin-right: 0;
      background-color: #004bb4;
      transition: .3s ease;
      &:hover{
        opacity: .7;
      }
      @include mq(sp){
        width: 60px;
      }
      a{
        display: block;
        width: 100%;
        height: 100%;
        padding: 17px 0 0 0;
        @include mq(sp){
          padding: 16px 3px 0 0;
        }
      }
      p{
        color: #fff;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
        margin-top: 8px;
      }
      img{
        height: 26px;
        @include mq(sp){
          height: 25px;
        }
      }
    }
    .shop-btn{
    }
    .contact-btn{
      background-color: #008ceb;
    }
  }
  @include mq(sp){
    .menu-btn{
      background-color: #008ceb;
      height: 100%;
      width: 60px;
      position: relative;
      span{
        width: 25px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: .3s ease;
        &:before{
          content: '';
          display: block;
          width: 25px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          bottom: 7px;
          right: 0;
          left: 0;
          margin: auto;
          transition: .3s ease;
        }
        &:after{
          content: '';
          display: block;
          width: 25px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 7px;
          right: 0;
          left: 0;
          margin: auto;
          transition: .3s ease;
        }
      }
    }
    .menu-btn.opened{
      span{
        background-color: rgba(255, 255, 255, 0);
        &:before{
          transform: rotate(-45deg);
          bottom: 0;
        }
        &:after{
          transform: rotate(45deg);
          top: 0;
        }
      }
    }
    .menu-btn.closed{
    }
    .nav{
      display: block;
      position: fixed;
      top: 55px;
      right: 0;
      background-color: rgba(50,50,50,.9);
      transform: translate(100vw,0);
      li{
        margin-right: 0;
        border-top: 1px solid #4b4b4b;
        &:first-child{
          border-top: none;
        }
      }
      p{
        width: 100%;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: 20px 30px 20px 45px;
        position: relative;
        &:before{
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 14px;
          margin: auto;
        }
        &:after{
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: #fff;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 19px;
          margin: auto;
        }
      }
    }
  }
}
