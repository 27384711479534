/*--------------------------------------------------------------------------
   reset
   --------------------------------------------------------------------------*/

html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,
sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,
figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 0; }

article,aside,details,figcaption,figure,main,footer,header,hgroup,menu,nav,section {
  display: block; }
ol,ul,li {
  list-style: none; }
blockquote,q {
  quotes: none; }
blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

img {
  border: 0;
  vertical-align: top; }
em {
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title],dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0; }

input,select,textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

input:focus,textarea:focus,select:focus {
  outline: none; }

//トゥイスク追加分（フォントスムージング）
body, p, h1, h2, h3, h4, h5, ul, li, table, tbody, th, td, a {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
