/*--------------------------------
modules
-----------------------------------*/
.border-btn{
  border: 1px solid #fff;
  width: 320px;
  height: 70px;
  margin: 0 auto;
  @include mq(sp){
    width: 160px;
    height: 40px;
  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: #fff;
      p{
        color: #4f5b5e;
      }
    }
  }
  p{
    text-align: center;
    color: #fff;
    line-height: 100%;
    font-size: 1.8rem;
    @include mq(sp){
      font-size: 1.2rem;
    }
  }
}

body.lower{
  .mv{
    height: 500px;
    position: relative;
    @include mq(sp){
      height: auto;
      min-height: 170px;
      text-align: center;
      background-size: cover;
    }
    &__txt{
      text-align: left;
      width: 1200px;
      margin: 0 auto;
      background-color: transparent;
      @include mq(sp){
        width: 100%;
        padding-left: 10px;
      }
    }
    &__title{
      font-size: 5.6rem;
      line-height: 5.6rem;
      font-weight: 500;
      letter-spacing: .9rem;
      padding-top: 170px;
      @include mq(sp){
        font-size: 2.1rem;
        line-height: 2.6rem;
        margin: 0;
        padding: 70px 0 0 0;
      }
    }
    &__desc{
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding-top: 25px;
      @include mq(sp){
        font-size: 1.2rem;
        padding: 0;
      }
    }
  }
}

#breadcrumb{
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(40,55,70,.35);
  padding: 20px 25px;
  ul{
    display: flex;
    text-align: left;
    li{
      font-size: 1.3rem;
      line-height: 1.3rem;
      &:first-child{
        p{
          &:before{
            content: none;
          }
        }
      }
      p{
        color: #fff;
        &:before{
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: #fff;
          transform: rotate(45deg);
          margin: 0 10px 0 5px;
          line-height: 0;
          vertical-align: middle;
        }
      }
    }
  }
}

.message-box{
  padding: 80px 0;
  min-height: 480px;
  position: relative;
  @include mq(sp){
    padding: 30px 0;
    min-height: 0;
  }
  &:before{
    content: '';
    display: block;
    width: 640px;
    height: 490px;
    position: absolute;
    right: 0;
    top: -80px;
    background: url(images/losscon/losscon_message-ph.jpg) center 0 no-repeat;
    background-size: cover;
    @include mq(sp){
      position: static;
      width: 100%;
      height: 240px;
    }
  }
  &__large{
    // background-color: #d2dae6;
    padding: 100px 0 0;
    // margin-top: 100px;
    &:before{
      width: 1200px;
      height: 510px;
      position: static;
      margin: 0 auto;
      @include mq(sp){
        width: 100%;
        height: 240px;
      }
    }
  }
  &__title{
    font-size: 1.8rem;
    color: #69829b;
    width: 260px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px 0;
    border-style: solid;
    border-color: #69829b;
    @include mq(sp){
      width: 225px;
      font-size: 1.6rem;
      height: 50px;
      margin-top: 30px;
    }
  }
  &__copy{
    font-size: 2.8rem;
    line-height: 4.6rem;
    width: 500px;
    padding-top: 34px;
    @include mq(sp){
      font-size: 2.1rem;
      line-height: 3.6rem;
      width: 100%;
      padding-top: 18px;
    }
  }
  &__desc{
    font-size: 1.5rem;
    line-height: 3rem;
    width: 455px;
    letter-spacing: .08em;
    padding-top: 6px;
    @include mq(sp){
      font-size: 1.2rem;
      line-height: 2rem;
      width: 100%;
    }
  }
  &__role{
    text-align: right;
    padding-right: 40px;
    font-size: 1.5rem;
    border-top: 1px solid #c3c8cd;
    // width: 500px;
    margin-left: auto;
    margin-top: 25px;
    padding-top: 25px;
    @include mq(sp){
      font-size: 1.2rem;
      line-height: 2rem;
      width: 100%;
      margin: 15px 0 0;
      padding-top: 15px;
    }
  }
  &__name{
    text-align: right;
    padding-right: 40px;
    font-size: 2.2rem;
    // width: 500px;
    margin-left: auto;
    @include mq(sp){
      font-size: 1.8rem;
      line-height: 2.2rem;
      width: 100%;
      margin: 5px 0 0;
    }
  }
}

.division{
  &__list{
    margin-top: 80px;
    background-color: #f0f2f6;
    padding: 20px 20px 10px;
    display: flex;
    align-items: center;
    @include mq(sp){
      flex-direction: column;
      padding: 30px 0 0;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: space-between;
      // width: 920px;
      width: 100%;
      @include mq(sp){
        padding: 0 10px;
      }
      li{
        width: 280px;
        height: 80px;
        margin-bottom: 10px;
        @include mq(sp){
          width: 50%;
          flex: 1 1 260px;
        }
        a{
          width: 100%;
          display: flex;
          align-items: center;
          background-color: #fff;
          color: #000;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
  }
  &__list-title{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2.8rem;
    line-height: 1;
    color: #69829b;
    @include mq(sp){
      // width: auto;
      margin-bottom: 25px;
    }
  }
  &__name{
    margin-left: 20px;
    font-size: 1.3rem;
  }
}
span.require{
  font-size: 1.4rem;
  color: #ed2e2e;
}