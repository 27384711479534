//-------------------------------------
// catalogページのスタイル
//-------------------------------------
#catalog{
  .mv{
    background-image: url(images/catalog/catalog_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .message-box{
    @include mq(sp){
      padding: 30px 0 0;
    }
    &:before{
      background-image: url(images/catalog/catalog_message-ph.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
    &__desc{
      padding-top: 50px;
      font-size: 1.8rem;
      line-height: 3.5rem;
      @include mq(sp){
      }
    }
  }
  .catalog{
    background-color: #d2dae6;
    @include mq(sp){
      background-color: transparent;
    }
    &__list{
      margin-top: 10px;
      padding: 50px 0 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // @extend .clearfix;
      @include mq(sp){
        margin: 30px auto 0;
        padding: 0;
      }
    }
    &__box{
      width: 580px;
      min-height: 310px;
      background-color: #fff;
      margin-top: 40px;
      display: flex;
      // float: right;
      &:last-child{
        .catalog__title{
          font-size: 2.1rem;
        }
      }
      @include mq(sp){
        width: 100%;
        height: auto;
        flex-direction: column;
        float: none;
      }
      // &__large{
      //   width: 520px;
      //   height: 660px;
      //   flex-direction: column;
      //   float: left;
      //   @include mq(sp){
      //     width: 100%;
      //     float: none;
      //     height: auto;
      //   }
      // }
    }
    &__ph{
      @include mq(sp){
        img{
          width: 100%;
        }
      }
    }
    &__textarea{
      padding: 32px 32px 96px;
      height: 100%;
      position: relative;
      @include mq(sp){
        padding: 20px;
      }
    }
    &__title{
      font-size: 1.8rem;
      line-height: 1.2;
      font-weight: 500;
      @include mq(sp){
        font-size: 1.8rem;
      }
    }
    &__desc{
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: .02em;
      margin: 8px 0 12px;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
    .border-btn{
      border-color: #004bb4;
      width: calc(100% - 64px);
      height: 64px;
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      margin: auto;
      @include mq(sp){
        width: 100%;
        height: 50px;
        position: static;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #fff;
          }
        }
      }
      p{
        color: #004bb4;
        font-size: 1.8rem;
        @include mq(sp){
          font-size: 1.4rem;
        }
      }
    }
  }
}