//-------------------------------------
// PPSページのスタイル
//-------------------------------------
#PPS{
  .mv{
    background-image: url(images/PPS/PPS_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .message-box{
    &:before{
      background-image: url(images/PPS/PPS_message-ph1.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
    &__reverse{
      padding: 0 0 80px;
      margin: 80px 0 0 0;
      min-height: 280px;
      @include mq(sp){
        margin: 30px 0 0;
        padding: 0;
        min-height: 0;
      }
      .l-inner{
        position: relative;
      }
      .message-box__textarea{
        width: 500px;
        margin-left: auto;
        @include mq(sp){
          width: 100%;
          padding-top: 25px;
        }
      }
      .message-box__copy{
        padding-top: 0;
      }
      &:before{
        background-image: url(images/PPS/PPS_message-ph2.jpg);
        right: auto;
        left: 0;
        height: 380px;
        @include mq(sp){
          height: 240px;
        }
      }
    }
  }
  .btn-list{
    background-color: #f0f2f6;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    @include mq(sp){
      flex-wrap: wrap;
      padding: 20px 0;
    }
    li{
      margin: 0 10px;
      @include mq(sp){
        margin: 5px;
      }
    }
    .border-btn{
      border-color: #004bb4;
      @include mq(sp){
        width: 280px;
        height: 60px;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #f0f2f6;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}