//-------------------------------------
// recruitページのスタイル
//-------------------------------------
#recruit{
  .mv{
    background-image: url(images/recruit/recruit_mv-bg.jpg);
  }
  .message-box{
    padding: 80px 0 60px;
    @include mq(sp){
      padding-top: 20px;
    }
    &:before{
      content: none;
      background-image: url(images/recruit/recruit_message-ph.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
    &__copy{
      padding-bottom: 10px;
    }
    &__desc{
      width: 100%;
    }
    &__role{
      border-top: none;
      margin-top: 10px;
      padding-right: 0;
    }
    &__name{
      padding-right: 0;
    }
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .workstyle{
    background-color: #d2dae6;
    padding: 80px 0;
    min-width: 1200px;
    @include mq(sp){
      padding: 0 0 40px;
      min-width: 0;
    }
    &__list-title{
      font-size: 2.8rem;
      line-height: 2.8rem;
      color: #69829b;
      &.interview{
        padding-top: 50px;
        @include mq(sp){
          padding-top: 70px;
        }
      }
      @include mq(sp){
        font-size: 2.3rem;
        margin-left: 10px;
        padding: 30px 0 0;
      }
    }
    &__list-subtitle{
      font-size: 1.6rem;
      line-height: 3rem;
      margin-top: 10px;
      color: #69829b;
      @include mq(sp){
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0;
        padding: 0 0 20px;
      }
    }
    &__list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      @include mq(sp){
        flex-direction: column;
        margin-top: 0;
      }
      &__large{
        .workstyle__box{
          @include mq(pc){
            width: 100%;
            max-height: 420px;
            display: flex;
            margin-bottom: 40px;
          }
          @include mq(sp){
            margin-top: 0;
          }
        }
      }
    }
    &__box{
      background-color: #fff;
      width: 580px;
      position: relative;
      @include mq(sp){
        width: 100%;
      }
    }
    &__ph{
      @include mq(sp){
        img{
          width: 100%;
        }
      }
    }
    &__textarea{
      padding: 85px 60px 130px;
      @include mq(sp){
        padding: 15px 15px 70px;
      }
    }
    &__copy{
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 500;
      padding: 0 0 10px;
      @include mq(sp){
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
    }
    &__subtitle{
      font-size: 2.2rem;
      line-height: 1;
      font-weight: 400;
      padding: 30px 0 5px;
      display: inline-block;
      color: #69829b;
      border-bottom: 1px solid #69829b;
      @include mq(sp){
        font-size: 1.6rem;
        padding: 10px 0 5px;
      }
    }
    &__desc{
      font-size: 1.5rem;
      line-height: 3.1rem;
      margin-top: 10px;
      letter-spacing: .08em;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
    &__name{
      font-size: 1.8rem;
      text-align: right;
      position: absolute;
      bottom: 60px;
      right: 60px;
      span{
        font-size: 1.4rem;
        margin-right: 15px;
      }
      @include mq(sp){
        right: 15px;
        bottom: 20px;
        font-size: 1.4rem;
        span{
          font-size: 1.1rem;
        }
      }
    }
  }
  .btn-list{
    display: flex;
    background-color: #f0f2f6;
    text-align: center;
    margin-top: 80px;
    @include mq(sp){
      flex-direction: column;
      margin-top: 0;
    }
    li{
      width: 50%;
      padding: 35px 0 45px;
      border-right: 1px solid #dce1eb;
      @include mq(sp){
        width: 100%;
        padding: 16px 0 20px;
        border-right: none;
      }
      &:nth-child(2n){
        border-right: none;
        border-left: 1px solid #dce1eb;
        @include mq(sp){
          border-left: none;
          border-top: 2px solid #dce1eb;
        }
      }
    }
    &__title{
      font-size: 2.8rem;
      line-height: 3rem;
      margin-bottom: 18px;
      @include mq(sp){
        font-size: 1.8rem;
        margin-bottom: 6px;
      }
    }
    .border-btn{
      border-color: #004bb4;
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #f0f2f6;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}