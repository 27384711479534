//-------------------------------------
// serviceページのスタイル
//-------------------------------------
#service{
  .mv{
    background-image: url(images/service/service_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .service{
    &__list{
      display: flex;
      flex-wrap: wrap;
      margin: 50px -10px 0;
      @include mq(sp){
        margin: 30px auto 0;
        justify-content: space-around;
      }
    }
    &__box{
      width: 285px;
      background-color: #fff;
      margin: 30px 10px 0;
      @include mq(sp){
        margin: 0 10px 15px;
        width: 100%;
      }
    }
    &__ph{
      @include mq(sp){
        img{
          width: 100%;
        }
      }
    }
    &__textarea{
      padding: 32px;
      @include mq(sp){
        padding: 20px;
      }
    }
    &__title{
      font-size: 2.2rem;
      line-height: 1.4;
      font-weight: 500;
      @include mq(sp){
        font-size: 2rem;
      }
    }
    &__desc{
      font-size: 1.4rem;
      line-height: 2.8rem;
      letter-spacing: .02em;
      margin: 12px 0 24px;
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin: 6px 0 12px;
      }
    }
    .border-btn{
      border-color: #004bb4;
      width: 180px;
      height: 50px;
      display: inline-block;
      @include mq(sp){
        width: 160px;
        height: 40px;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #f0f2f6;
          }
        }
      }
      p{
        color: #004bb4;
        font-size: 1.4rem;
        @include mq(sp){
          font-size: 1.2rem;
        }
      }
    }
  }
}