//-------------------------------------
// companyページのスタイル
//-------------------------------------
#company{
  .mv{
    background-image: url(images/company/company_mv-bg.jpg);
  }
  .message-box{
    &:before{
      background-image: url(images/company/company_message-ph.jpg);
      @include mq(sp){
        background-position: left 0 top 20%;
      }
    }
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .about{
    background-color: #d2dae6;
    padding-top: 100px;
    margin-top: 100px;
    min-width: 1200px;
    @include mq(sp){
      min-width: 0;
      padding-top: 60px;
      margin-top: 60px;
    }
    .philosophy{
      margin: -200px auto 0;
      text-align: center;
      background-color: #fff;
      @include mq(sp){
        margin-top: -120px;
      }
      &__title{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem;
        @include mq(sp){
          height: 60px;
          font-size: 1.8rem;
        }
      }
      &__copy{
        font-size: 3rem;
        line-height: 4.6rem;
        border-top: 1px solid #d2dae6;
        padding: 40px 0 15px;
        font-weight: 600;
        @include mq(sp){
          font-size: 2.1rem;
          line-height: 3rem;
          padding: 20px 0 10px;
        }
      }
      &__desc{
        width: 455px;
        margin: 0 auto;
        padding-bottom: 40px;
        font-size: 1.5rem;
        letter-spacing: .08em;
        @include mq(sp){
          width: 100%;
          font-size: 1.2rem;
          padding: 0 10px 30px;
        }
      }
    }
    .service{
      margin: 80px 0 0;
      padding: 0 0 80px;
      position: relative;
      @include mq(sp){
        margin-top: 40px;
        padding-bottom: 40px;
      }
      &__list-title{
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: #69829b;
        @include mq(sp){
          font-size: 2.3rem;
          margin-left: 10px;
        }
      }
      &__list-subtitle{
        font-size: 1.6rem;
        line-height: 3rem;
        margin-top: 10px;
        color: #69829b;
        @include mq(sp){
          font-size: 1.2rem;
          margin-left: 10px;
          margin-top: 0;
        }
      }
      &__list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;
        @include mq(sp){
          margin: 30px auto 0;
          justify-content: space-around;
        }
      }
      &__box{
        width: 285px;
        background-color: #fff;
        @include mq(sp){
          margin: 0 10px 15px;
          width: 100%;
        }
      }
      &__ph{
        @include mq(sp){
          img{
            width: 100%;
          }
        }
      }
      &__textarea{
        padding: 30px;
        @include mq(sp){
          padding: 20px;
        }
      }
      &__title{
        font-size: 2rem;
        line-height: 2.7rem;
        font-weight: 500;
        @include mq(sp){
          font-size: 1.8rem;
          line-height: 2.8rem;
        }
      }
      &__desc{
        font-size: 1.4rem;
        line-height: 2.6rem;
        letter-spacing: .08em;
        margin-top: 8px;
        @include mq(sp){
          font-size: 1.2rem;
        }
      }
      .border-btn{
        border-color: #69829b;
        width: 180px;
        height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        @include mq(sp){
          position: static;
          margin-top: 15px;
        }
        a{
          &:hover{
            background-color: #69829b;
            p{
              color: #dce1eb;
            }
          }
        }
        p{
          color: #69829b;
          font-size: 1.4rem;
        }
      }
    }
  }
  .link-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 80px;
    @include mq(sp){
      justify-content: space-around;
      margin-top: 50px;
    }
    &__box{
      width: 580px;
      background-color: #fff;
      @include mq(sp){
        width: 100%;
        margin-top: 15px;
        &:first-child{
          margin-top: 0;
        }
      }
    }
    &__ph{
      @include mq(sp){
        img{
          width: 100%;
        }
      }
    }
    &__textarea{
      padding: 60px;
      @include mq(sp){
        padding: 30px;
      }
    }
    &__title{
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 500;
      @include mq(sp){
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }
    &__desc{
      font-size: 1.4rem;
      line-height: 2.8rem;
      letter-spacing: .08em;
      margin: 18px 0 0;
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin: 10px 0 16px;
      }
    }
    .border-btn{
      display: inline-block;
      border-color: #004bb4;
      margin-top: 20px;
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #fff;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}