//-------------------------------------
// overviewページのスタイル
//-------------------------------------
#overview{
  .mv{
    background-image: url(images/overview/overview_mv-bg.jpg);
  }
  .maincontent{
    margin-top: 0;
    background-color: transparent;
  }
  .overview{
    background-color: #fff;
    padding: 60px;
    margin: 80px 0;
    @include mq(sp){
      padding: 20px;
      margin: 30px 0;
    }
    &__title{
      text-align: center;
      font-size: 2.8rem;
      line-height: 2.8rem;
      font-weight: 500;
      margin-bottom: 35px;
      @include mq(sp){
        font-size: 2.2rem;
        margin-bottom: 15px
      }
    }
    table{
      width: 100%;
    }
    tr{
      width: 100%;
      padding: 18px 0;
      display: block;
      border-bottom: 1px solid #e0e0e0;
      @include mq(sp){
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px;
      }
      &:first-child{
        border-top: 1px solid #e0e0e0;
      }
    }
    th{
      width: 220px;
      font-weight: 500;
      font-size: 1.6rem;
      padding: 0 0 0 10px;
      vertical-align: top;
      text-align: left;
      line-height: 2.2rem;
      span{
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
      @include mq(sp){
        padding: 0;
        font-size: 1.4rem;
        flex: 0 0 auto;
      }
    }
    td{
      font-size: 1.4rem;
      line-height: 2.2rem;
      >ul{
        >li{
          margin-top: 6px;
          &:first-child{
            margin-top: 0;
          }
        }
      }
      ul{
        ul{
          font-size: 1.2rem;
          li{
            p{
              &:before{
                content: '･';
              }
            }
          }
        }
      }
      @include mq(sp){
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
  .history{
    background-color: #d2dae6;
    padding: 80px 0 50px;
    min-width: 1200px;
    @include mq(sp){
      padding: 30px 10px;
      min-width: 0;
    }
    &__list-title{
      font-size: 2.8rem;
      line-height: 2.8rem;
      color: #69829b;
      @include mq(sp){
        font-size: 2.3rem;
        margin-left: 10px;
      }
    }
    &__list-subtitle{
      font-size: 1.6rem;
      line-height: 3rem;
      margin-top: 10px;
      color: #69829b;
      @include mq(sp){
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0;
      }
    }
    &__list{
      position: relative;
      margin-top: 20px;
      &:before{
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background-color: #69829b;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
      }
    }
    li{
      background-color: #fff;
      @include mq(sp){
        margin-bottom: 20px;
        width: 100%;
        height: auto;
        padding: 20px 0;
      }
    }
    &__box-l{
      width: 550px;
      height: 114px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;
      margin: 0 0 30px;
      @include mq(sp){
      }
      &:after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-width: 57px 0 57px 40px;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 0;
        right: -40px;
        @include mq(sp){
          content: none;
        }
      }
    }
    &__box-r{
      width: 550px;
      height: 114px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      position: absolute;
      z-index: 10;
      right: 0;
      margin-top: -74px;
      @include mq(sp){
        position: relative;
        margin: 0;
        flex-direction: row;
        justify-content: flex-start;
      }
      &:after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-width: 57px 40px 57px 0;
        border-style: solid;
        border-color: transparent #fff transparent transparent;
        position: absolute;
        top: 0;
        left: -40px;
        @include mq(sp){
          content: none;
        }
      }
      .history__textarea{
        padding: 0 0 0 20px;
        @include mq(sp){
          padding: 0;
        }
      }
    }
    &__box-photo{
      width: 100%;
      height: 214px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;
      margin-bottom: 30px;
      @include mq(sp){
        height: 150px;
        padding-bottom: 0 !important;
        flex-wrap: wrap;
      }
    }
    &__box-large{
      width: 100%;
      height: 114px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;
      margin-bottom: 30px;
      @include mq(sp){
      }
    }
    &__textarea{
      flex: 1 1 0;
    }
    &__year{
      font-family: 'Ropa Sans', sans-serif;
      color: #69829b;
      font-size: 5.6rem;
      line-height: 3.4rem;
      font-weight: 500;
      letter-spacing: 0;
      margin: 0 30px;
      @include mq(sp){
        width: 24vw;
        max-width: 90px;
        margin: 0 10px;
        font-size: 4.6rem;
        line-height: 3rem;
        letter-spacing: -0.1rem;
      }
    }
    &__ph{
      margin-left: auto;
      @include mq(sp){
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
    &__title{
      font-size: 1.8rem;
      line-height: 1.8rem;
      letter-spacing: .08em;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
    &__desc{
      font-size: 1.4rem;
      line-height: 1.4rem;
      letter-spacing: .08em;
      margin-top: 4px;
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
  }
  .btn-list{
    background-color: #f0f2f6;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    margin-top: 80px;
    @include mq(sp){
      flex-wrap: wrap;
      padding: 20px 0;
      margin-top: 40px;
    }
    li{
      margin: 0 10px;
      @include mq(sp){
        margin: 5px 10px;
        flex: 1 1 280px;
        width: 100%;
      }
    }
    .border-btn{
      border-color: #004bb4;
      @include mq(sp){
        width: 100%;
        height: 60px;
      }
      a{
        &:hover{
          background-color: #004bb4;
          p{
            color: #f0f2f6;
          }
        }
      }
      p{
        color: #004bb4;
      }
    }
  }
}