@charset "UTF-8";
/*--------------------------------------------------------------------------
   reset
   --------------------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 0; }

article, aside, details, figcaption, figure, main, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

img {
  border: 0;
  vertical-align: top; }

em {
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0; }

input, select, textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

input:focus, textarea:focus, select:focus {
  outline: none; }

body, p, h1, h2, h3, h4, h5, ul, li, table, tbody, th, td, a {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------------
変数
-------------------------------*/
@media (max-width: 750px) {
  .object-pc {
    display: none; } }

@media (min-width: 751px) {
  .object-sp {
    display: none; } }

@keyframes loading {
  0% {
    opacity: 1; }
  99% {
    opacity: 0;
    z-index: 100; }
  100% {
    opacity: 0;
    z-index: -9999; } }

/*--------------------------------------------------------------------------
 *   default;
 *---------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------
 *	 html, body;
 *---------------------------------------------------------------------------*/
* {
  box-sizing: border-box; }

html {
  font-size: 62.5% !important; }

html, body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  letter-spacing: 1.2px;
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  color: #000;
  line-height: 1.87; }

body {
  background-color: #dce1eb;
  font-size: 1.6em;
  letter-spacing: .12em; }

/*--------------------------------------------------------------------------
 *	 base link;
 *---------------------------------------------------------------------------*/
a {
  outline: none;
  opacity: 1;
  -webkit-tap-highlight-color: transparent;
  transition-duration: .3s; }
  a:link, a:visited {
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    outline: none; }

/* clear fix */
.clearfix, .l-footer .f-nav, .l-footer .f-info {
  *zoom: 1; }
  .clearfix:after, .l-footer .f-nav:after, .l-footer .f-info:after {
    content: "";
    display: block;
    clear: both; }

/* img */
@media (max-width: 750px) {
  img {
    max-width: 100%; } }

/*--------------------------------------------------------------------------
 *   form style;
 *---------------------------------------------------------------------------*/
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none; }

.l-inner {
  min-width: 1200px;
  max-width: 1520px;
  margin: 0 auto; }
  @media (max-width: 750px) {
    .l-inner {
      min-width: 0;
      max-width: 730px; } }

.lower .maincontent .l-inner {
  max-width: 1200px; }

.l-content {
  padding-top: 90px;
  min-width: 1200px; }
  @media (max-width: 750px) {
    .l-content {
      padding-top: 55px;
      min-width: 0; } }

.l-header {
  background-color: #fff;
  height: 90px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 100, 1); }
  @media (max-width: 750px) {
    .l-header {
      height: 55px; } }
  .l-header .l-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 100%; }
  .l-header .logo {
    margin: 0 0 0 20px; }
    @media (max-width: 750px) {
      .l-header .logo {
        margin: 0 0 0 10px; } }
    .l-header .logo img {
      height: 42px; }
      @media (max-width: 750px) {
        .l-header .logo img {
          height: auto;
          max-height: 30px; } }
  .l-header .g-nav {
    display: flex;
    justify-content: space-between;
    height: 100%; }
    @media (min-width: 751px) {
      .l-header .g-nav .nav li {
        transition: .3s ease; }
        .l-header .g-nav .nav li:after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background-color: #004bb4;
          transition: .3s ease; }
        .l-header .g-nav .nav li:hover p, .l-header .g-nav .nav li.active p {
          color: #004bb4; }
        .l-header .g-nav .nav li:hover:after, .l-header .g-nav .nav li.active:after {
          width: 100%; } }
    .l-header .g-nav ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 15px; }
      @media (max-width: 750px) {
        .l-header .g-nav ul {
          display: block;
          transition: .4s ease; } }
    .l-header .g-nav li {
      margin-right: 30px; }
    .l-header .g-nav p {
      color: #000;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: .03rem;
      font-weight: 500; }
    .l-header .g-nav .nav-btn {
      height: 100%;
      width: 110px;
      text-align: center;
      margin-right: 0;
      background-color: #004bb4;
      transition: .3s ease; }
      .l-header .g-nav .nav-btn:hover {
        opacity: .7; }
      @media (max-width: 750px) {
        .l-header .g-nav .nav-btn {
          width: 60px; } }
      .l-header .g-nav .nav-btn a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 17px 0 0 0; }
        @media (max-width: 750px) {
          .l-header .g-nav .nav-btn a {
            padding: 16px 3px 0 0; } }
      .l-header .g-nav .nav-btn p {
        color: #fff;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
        margin-top: 8px; }
      .l-header .g-nav .nav-btn img {
        height: 26px; }
        @media (max-width: 750px) {
          .l-header .g-nav .nav-btn img {
            height: 25px; } }
    .l-header .g-nav .contact-btn {
      background-color: #008ceb; }
  @media (max-width: 750px) {
    .l-header .menu-btn {
      background-color: #008ceb;
      height: 100%;
      width: 60px;
      position: relative; }
      .l-header .menu-btn span {
        width: 25px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: .3s ease; }
        .l-header .menu-btn span:before {
          content: '';
          display: block;
          width: 25px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          bottom: 7px;
          right: 0;
          left: 0;
          margin: auto;
          transition: .3s ease; }
        .l-header .menu-btn span:after {
          content: '';
          display: block;
          width: 25px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 7px;
          right: 0;
          left: 0;
          margin: auto;
          transition: .3s ease; }
    .l-header .menu-btn.opened span {
      background-color: rgba(255, 255, 255, 0); }
      .l-header .menu-btn.opened span:before {
        transform: rotate(-45deg);
        bottom: 0; }
      .l-header .menu-btn.opened span:after {
        transform: rotate(45deg);
        top: 0; }
    .l-header .nav {
      display: block;
      position: fixed;
      top: 55px;
      right: 0;
      background-color: rgba(50, 50, 50, 0.9);
      transform: translate(100vw, 0); }
      .l-header .nav li {
        margin-right: 0;
        border-top: 1px solid #4b4b4b; }
        .l-header .nav li:first-child {
          border-top: none; }
      .l-header .nav p {
        width: 100%;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: 20px 30px 20px 45px;
        position: relative; }
        .l-header .nav p:before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 14px;
          margin: auto; }
        .l-header .nav p:after {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: #fff;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 19px;
          margin: auto; } }

.l-footer {
  padding-top: 100px; }
  @media (max-width: 750px) {
    .l-footer {
      margin: 0 10px;
      padding-top: 40px; } }
  .l-footer .f-nav, .l-footer .f-info {
    border-bottom: 1px solid #c3c8cd;
    padding: 0 10px; }
    @media (max-width: 750px) {
      .l-footer .f-nav, .l-footer .f-info {
        border: none;
        padding: 0;
        overflow: hidden;
        height: 0; } }
  .l-footer .f-nav-btn, .l-footer .f-info-btn {
    width: 100%;
    background-color: #91a5be; }
    .l-footer .f-nav-btn p, .l-footer .f-info-btn p {
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: #fff;
      text-align: center;
      padding: 18px 0;
      position: relative; }
      .l-footer .f-nav-btn p:after, .l-footer .f-info-btn p:after {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background: url(images/common/plus.png) center 0 no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;
        margin: auto; }
  .l-footer .f-nav-btn.opened p:after, .l-footer .f-info-btn.opened p:after {
    background-image: url(images/common/minus.png); }
  .l-footer .f-nav {
    border-top: 1px solid #c3c8cd; }
    @media (max-width: 750px) {
      .l-footer .f-nav {
        border: none;
        margin-bottom: 20px; } }
    .l-footer .f-nav__title {
      font-size: 1.6rem;
      line-height: 4.2rem;
      letter-spacing: 0.12rem;
      color: #000;
      position: relative;
      padding-left: 25px;
      transition: .3s ease; }
      .l-footer .f-nav__title:hover {
        opacity: .7; }
      @media (max-width: 750px) {
        .l-footer .f-nav__title {
          font-size: 1.4rem;
          border-top: 1px solid #c3c8cd; } }
      .l-footer .f-nav__title:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #004bb4;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
      .l-footer .f-nav__title:after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        border-color: #004bb4;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 5px;
        margin: auto; }
    .l-footer .f-nav > li {
      float: left;
      width: 20%;
      min-height: 180px;
      margin: 30px 0;
      padding-left: 30px;
      border-left: 1px solid #c3c8cd; }
      @media (max-width: 750px) {
        .l-footer .f-nav > li {
          float: none;
          width: 100%;
          max-width: 375px;
          min-height: 0;
          margin: 0 auto;
          border-left: none;
          padding-left: 0; } }
      .l-footer .f-nav > li:first-child {
        border-left: none; }
      @media (max-width: 750px) {
        .l-footer .f-nav > li:last-child a:last-child .f-nav__title {
          border-bottom: 1px solid #c3c8cd; } }
    .l-footer .f-nav ul {
      margin: 0 0 10px 20px; }
      .l-footer .f-nav ul li p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: 0.12rem;
        color: #000;
        position: relative;
        padding-left: 16px;
        transition: .3s ease; }
        .l-footer .f-nav ul li p:hover {
          opacity: .7; }
        @media (max-width: 750px) {
          .l-footer .f-nav ul li p {
            font-size: 1.2rem; } }
        .l-footer .f-nav ul li p:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: #004bb4;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto; }
  .l-footer .f-info > li {
    float: left;
    width: 20%;
    min-height: 180px;
    margin: 30px 0;
    padding-left: 35px;
    border-left: 1px solid #c3c8cd; }
    @media (max-width: 750px) {
      .l-footer .f-info > li {
        float: none;
        width: 100%;
        max-width: 375px;
        min-height: 0;
        margin: 0 auto;
        border-left: none;
        padding: 10px 0 15px 25px;
        border-bottom: 1px solid #c3c8cd; } }
    .l-footer .f-info > li:first-child {
      border-left: none; }
  .l-footer .f-info dt {
    font-size: 1.6rem;
    line-height: 4.2rem;
    letter-spacing: 0.12rem;
    color: #000; }
    @media (max-width: 750px) {
      .l-footer .f-info dt {
        font-size: 1.4rem; } }
  .l-footer .f-info dd {
    font-size: 1.3rem;
    line-height: 2.2rem;
    letter-spacing: 0;
    color: #000; }
    @media (max-width: 750px) {
      .l-footer .f-info dd {
        font-size: 1.2rem; } }
  .l-footer .copyright {
    font-size: 1.1rem;
    line-height: 2.2rem;
    letter-spacing: .12rem;
    text-align: center;
    color: #000;
    margin: 55px 0; }
    @media (max-width: 750px) {
      .l-footer .copyright {
        margin: 30px 0;
        font-size: 1rem;
        letter-spacing: .03rem; } }

.pagetop {
  width: 60px;
  height: 60px;
  background-color: rgba(83, 108, 138, 0.5);
  position: fixed;
  bottom: 72px;
  right: 0;
  z-index: 100;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 100, 1);
  transition: auto;
  cursor: pointer;
  display: none; }
  @media (max-width: 750px) {
    .pagetop {
      width: 45px;
      height: 45px;
      right: 10px;
      bottom: 15px; } }
  .pagetop:hover {
    background-color: #2d4664; }
  .pagetop span {
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg) translate(15%, 15%);
    width: 10px;
    height: 10px; }

/*--------------------------------
news-unit
-----------------------------------*/
.news-unit {
  background-color: #fff;
  margin: 45px auto;
  padding: 20px 50px 20px 0;
  width: calc(100% - 60px);
  display: flex;
  align-items: center; }
  @media (max-width: 750px) {
    .news-unit {
      width: calc(100% - 20px);
      display: block;
      padding: 15px;
      margin: 32px auto; } }
  .news-unit__label {
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: .12em;
    text-align: center;
    width: 260px;
    flex: 0 0 auto; }
    @media (max-width: 750px) {
      .news-unit__label {
        width: 100%;
        font-size: 2rem; } }
  .news-unit__list {
    width: 100%; }
    .news-unit__list li {
      width: 100%; }
      .news-unit__list li:nth-child(n+2) {
        border-top: 1px solid #c3c8cd; }
  .news-unit__ttl {
    display: flex;
    font-size: 1.6rem;
    letter-spacing: .12em;
    color: #000;
    padding: .75em 1.5em .75em 0;
    position: relative;
    z-index: 0;
    cursor: pointer;
    transition: opacity .3s ease; }
    @media (max-width: 750px) {
      .news-unit__ttl {
        display: block;
        font-size: 1.4rem; } }
    .news-unit__ttl:hover {
      opacity: .7; }
    .news-unit__ttl:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #004bb4;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
    .news-unit__ttl:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: #004bb4;
      transform: rotate(135deg);
      position: absolute;
      top: 0;
      bottom: 2px;
      right: 6px;
      margin: auto; }
    .news-unit__ttl.-active:after {
      transform: rotate(-45deg);
      top: 2px;
      bottom: 0; }
  .news-unit__date {
    font-weight: 400;
    margin: 0 1em 0 0; }
  .news-unit__text {
    font-weight: 500; }
  .news-unit__content {
    display: none;
    padding: 1em;
    background-color: #f0f2f6;
    margin: 0 0 1em; }
    .news-unit__content p {
      font-size: 1.4rem;
      line-height: 1.8; }
      @media (max-width: 750px) {
        .news-unit__content p {
          font-size: 1.3rem; } }
      .news-unit__content p + p {
        margin: 1em 0 0; }
    .news-unit__content a {
      text-decoration: underline;
      color: #004bb4;
      margin: 0 1em 0 0; }

/*--------------------------------
modules
-----------------------------------*/
.border-btn {
  border: 1px solid #fff;
  width: 320px;
  height: 70px;
  margin: 0 auto; }
  @media (max-width: 750px) {
    .border-btn {
      width: 160px;
      height: 40px; } }
  .border-btn a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .border-btn a:hover {
      background-color: #fff; }
      .border-btn a:hover p {
        color: #4f5b5e; }
  .border-btn p {
    text-align: center;
    color: #fff;
    line-height: 100%;
    font-size: 1.8rem; }
    @media (max-width: 750px) {
      .border-btn p {
        font-size: 1.2rem; } }

body.lower .mv {
  height: 500px;
  position: relative; }
  @media (max-width: 750px) {
    body.lower .mv {
      height: auto;
      min-height: 170px;
      text-align: center;
      background-size: cover; } }
  body.lower .mv__txt {
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    background-color: transparent; }
    @media (max-width: 750px) {
      body.lower .mv__txt {
        width: 100%;
        padding-left: 10px; } }
  body.lower .mv__title {
    font-size: 5.6rem;
    line-height: 5.6rem;
    font-weight: 500;
    letter-spacing: .9rem;
    padding-top: 170px; }
    @media (max-width: 750px) {
      body.lower .mv__title {
        font-size: 2.1rem;
        line-height: 2.6rem;
        margin: 0;
        padding: 70px 0 0 0; } }
  body.lower .mv__desc {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding-top: 25px; }
    @media (max-width: 750px) {
      body.lower .mv__desc {
        font-size: 1.2rem;
        padding: 0; } }

#breadcrumb {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(40, 55, 70, 0.35);
  padding: 20px 25px; }
  #breadcrumb ul {
    display: flex;
    text-align: left; }
    #breadcrumb ul li {
      font-size: 1.3rem;
      line-height: 1.3rem; }
      #breadcrumb ul li:first-child p:before {
        content: none; }
      #breadcrumb ul li p {
        color: #fff; }
        #breadcrumb ul li p:before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: #fff;
          transform: rotate(45deg);
          margin: 0 10px 0 5px;
          line-height: 0;
          vertical-align: middle; }

.message-box {
  padding: 80px 0;
  min-height: 480px;
  position: relative; }
  @media (max-width: 750px) {
    .message-box {
      padding: 30px 0;
      min-height: 0; } }
  .message-box:before {
    content: '';
    display: block;
    width: 640px;
    height: 490px;
    position: absolute;
    right: 0;
    top: -80px;
    background: url(images/losscon/losscon_message-ph.jpg) center 0 no-repeat;
    background-size: cover; }
    @media (max-width: 750px) {
      .message-box:before {
        position: static;
        width: 100%;
        height: 240px; } }
  .message-box__large {
    padding: 100px 0 0; }
    .message-box__large:before {
      width: 1200px;
      height: 510px;
      position: static;
      margin: 0 auto; }
      @media (max-width: 750px) {
        .message-box__large:before {
          width: 100%;
          height: 240px; } }
  .message-box__title {
    font-size: 1.8rem;
    color: #69829b;
    width: 260px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px 0;
    border-style: solid;
    border-color: #69829b; }
    @media (max-width: 750px) {
      .message-box__title {
        width: 225px;
        font-size: 1.6rem;
        height: 50px;
        margin-top: 30px; } }
  .message-box__copy {
    font-size: 2.8rem;
    line-height: 4.6rem;
    width: 500px;
    padding-top: 34px; }
    @media (max-width: 750px) {
      .message-box__copy {
        font-size: 2.1rem;
        line-height: 3.6rem;
        width: 100%;
        padding-top: 18px; } }
  .message-box__desc {
    font-size: 1.5rem;
    line-height: 3rem;
    width: 455px;
    letter-spacing: .08em;
    padding-top: 6px; }
    @media (max-width: 750px) {
      .message-box__desc {
        font-size: 1.2rem;
        line-height: 2rem;
        width: 100%; } }
  .message-box__role {
    text-align: right;
    padding-right: 40px;
    font-size: 1.5rem;
    border-top: 1px solid #c3c8cd;
    margin-left: auto;
    margin-top: 25px;
    padding-top: 25px; }
    @media (max-width: 750px) {
      .message-box__role {
        font-size: 1.2rem;
        line-height: 2rem;
        width: 100%;
        margin: 15px 0 0;
        padding-top: 15px; } }
  .message-box__name {
    text-align: right;
    padding-right: 40px;
    font-size: 2.2rem;
    margin-left: auto; }
    @media (max-width: 750px) {
      .message-box__name {
        font-size: 1.8rem;
        line-height: 2.2rem;
        width: 100%;
        margin: 5px 0 0; } }

.division__list {
  margin-top: 80px;
  background-color: #f0f2f6;
  padding: 20px 20px 10px;
  display: flex;
  align-items: center; }
  @media (max-width: 750px) {
    .division__list {
      flex-direction: column;
      padding: 30px 0 0; } }
  .division__list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: space-between;
    width: 100%; }
    @media (max-width: 750px) {
      .division__list ul {
        padding: 0 10px; } }
    .division__list ul li {
      width: 280px;
      height: 80px;
      margin-bottom: 10px; }
      @media (max-width: 750px) {
        .division__list ul li {
          width: 50%;
          flex: 1 1 260px; } }
      .division__list ul li a {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        color: #000;
        font-size: 1.6rem;
        line-height: 2.4rem; }

.division__list-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.8rem;
  line-height: 1;
  color: #69829b; }
  @media (max-width: 750px) {
    .division__list-title {
      margin-bottom: 25px; } }

.division__name {
  margin-left: 20px;
  font-size: 1.3rem; }

span.require {
  font-size: 1.4rem;
  color: #ed2e2e; }

#A-one .mv {
  background-image: url(images/A-one/A-one_mv-bg.jpg); }

#A-one .maincontent {
  margin-top: 0;
  background-color: transparent; }

#A-one .message-box:before {
  background-image: url(images/A-one/A-one_message-ph1.jpg); }
  @media (max-width: 750px) {
    #A-one .message-box:before {
      background-position: left 0 top 20%; } }

#A-one .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0;
  min-height: 280px; }
  @media (max-width: 750px) {
    #A-one .message-box__reverse {
      margin: 30px 0 0;
      padding: 0;
      min-height: 0; } }
  #A-one .message-box__reverse .l-inner {
    position: relative; }
  #A-one .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #A-one .message-box__reverse .message-box__textarea {
        width: 100%;
        padding-top: 25px; } }
  #A-one .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #A-one .message-box__reverse:before {
    background-image: url(images/A-one/A-one_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }
    @media (max-width: 750px) {
      #A-one .message-box__reverse:before {
        height: 240px; } }

#A-one .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #A-one .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #A-one .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #A-one .btn-list li {
        margin: 5px; } }
  #A-one .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #A-one .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #A-one .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #A-one .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #A-one .btn-list .border-btn p {
      color: #004bb4; }

#application .mv {
  background-image: url(images/application/application_mv-bg.jpg); }

#application .message-box:before {
  background-image: url(images/application/application_message-ph.jpg); }
  @media (max-width: 750px) {
    #application .message-box:before {
      background-position: left 0 top 20%; } }

#application .maincontent {
  margin-top: 0;
  background-color: transparent;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #application .maincontent {
      min-width: 0; } }

@media (max-width: 750px) {
  #application .application {
    margin-top: 40px;
    padding-bottom: 40px; } }

#application .application__box {
  padding: 80px 0 30px;
  background-color: #fff; }
  @media (max-width: 750px) {
    #application .application__box {
      padding: 30px 10px 20px; } }

#application .application__copy {
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #69829b;
  position: relative;
  letter-spacing: .1em;
  padding: 20px 0 40px; }
  #application .application__copy:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 18px auto 0;
    background-color: #69829b; }
    @media (max-width: 750px) {
      #application .application__copy:after {
        margin-top: 10px; } }
  @media (max-width: 750px) {
    #application .application__copy {
      font-size: 2.3rem; } }

#application .application__desc {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 25px; }
  @media (max-width: 750px) {
    #application .application__desc {
      font-size: 1.2rem; } }

#application .application .formtable {
  width: 100%;
  margin: 40px 0 30px; }
  #application .application .formtable__name input[type="text"] {
    width: 35%; }
    @media (max-width: 750px) {
      #application .application .formtable__name input[type="text"] {
        width: 70%; } }
  #application .application .formtable__phonetic input[type="text"] {
    width: 35%; }
    @media (max-width: 750px) {
      #application .application .formtable__phonetic input[type="text"] {
        width: 70%; } }
  #application .application .formtable__email input[type="text"] {
    width: 55%; }
    @media (max-width: 750px) {
      #application .application .formtable__email input[type="text"] {
        width: 80%; } }
  #application .application .formtable__tel input[type="text"] {
    width: 30%; }
    @media (max-width: 750px) {
      #application .application .formtable__tel input[type="text"] {
        width: 75%; } }
  #application .application .formtable__job label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-right: 20px;
    transition: .3s ease; }
    #application .application .formtable__job label input[type="radio"]:checked + .lever:before {
      opacity: 0;
      background: #dce1eb;
      border-color: #dce1eb;
      transform: scale(2); }
    #application .application .formtable__job label input[type="radio"]:checked + .lever:after {
      opacity: 1;
      position: absolute;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 14px;
      border-right: 2px solid #69829b;
      border-bottom: 2px solid #69829b;
      transform: rotate(40deg); }
    #application .application .formtable__job label .lever {
      font-size: 1.5rem;
      line-height: 1.5rem; }
      #application .application .formtable__job label .lever:before {
        content: '';
        border: 1px solid #69829b;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        transition: .4s ease; }
      #application .application .formtable__job label .lever:after {
        content: '';
        opacity: 0;
        position: absolute;
        left: 8px;
        top: 0px;
        width: 3px;
        height: 7px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-310deg);
        transition: .15s ease-out; }
  #application .application .formtable__post input[type="text"] {
    width: 30%; }
    @media (max-width: 750px) {
      #application .application .formtable__post input[type="text"] {
        width: 70%; } }
  #application .application .formtable__address input[type="text"] {
    width: 80%; }
    @media (max-width: 750px) {
      #application .application .formtable__address input[type="text"] {
        width: 100%; } }

#application .application tr {
  border-bottom: 1px solid #ddd; }
  #application .application tr:first-child {
    border-top: 1px solid #ddd; }

#application .application th {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 500;
  width: 200px;
  padding: 30px 0;
  vertical-align: top; }
  @media (max-width: 750px) {
    #application .application th {
      width: 100%;
      display: block;
      font-size: 1.4rem;
      padding: 20px 0 0; } }

#application .application td {
  padding: 30px 0 30px 30px;
  font-size: 1.6rem; }
  @media (max-width: 750px) {
    #application .application td {
      width: 100%;
      display: block;
      padding: 10px 0 20px;
      font-size: 1.4rem; } }

#application .application input[type="text"] {
  background-color: #fff;
  border: 1px solid #91a5be;
  padding: 10px;
  width: 100%; }
  #application .application input[type="text"]:focus {
    box-shadow: 0 0 8px rgba(105, 130, 155, 0.3); }

#application .application textarea {
  background-color: #fff;
  border: 1px solid #91a5be;
  padding: 10px;
  width: 100% !important;
  height: 160px !important; }
  #application .application textarea:focus {
    box-shadow: 0 0 8px rgba(105, 130, 155, 0.3); }

#application .application .border-btn {
  border-color: #004bb4;
  display: flex;
  justify-content: center;
  align-items: center; }
  #application .application .border-btn p {
    display: block;
    width: 100%;
    height: 100%; }
  #application .application .border-btn input {
    font-size: 1.8rem;
    line-height: 1.8rem;
    width: 100%;
    height: 100%;
    display: block;
    color: #fff;
    background-color: #004bb4;
    cursor: pointer;
    transition: .3s ease; }
    @media (max-width: 750px) {
      #application .application .border-btn input {
        font-size: 1.4rem; } }
    #application .application .border-btn input:hover {
      background-color: transparent;
      color: #004bb4; }

#catalog .mv {
  background-image: url(images/catalog/catalog_mv-bg.jpg); }

#catalog .maincontent {
  margin-top: 0;
  background-color: transparent; }

@media (max-width: 750px) {
  #catalog .message-box {
    padding: 30px 0 0; } }

#catalog .message-box:before {
  background-image: url(images/catalog/catalog_message-ph.jpg); }
  @media (max-width: 750px) {
    #catalog .message-box:before {
      background-position: left 0 top 20%; } }

#catalog .message-box__desc {
  padding-top: 50px;
  font-size: 1.8rem;
  line-height: 3.5rem; }

#catalog .catalog {
  background-color: #d2dae6; }
  @media (max-width: 750px) {
    #catalog .catalog {
      background-color: transparent; } }
  #catalog .catalog__list {
    margin-top: 10px;
    padding: 50px 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 750px) {
      #catalog .catalog__list {
        margin: 30px auto 0;
        padding: 0; } }
  #catalog .catalog__box {
    width: 580px;
    min-height: 310px;
    background-color: #fff;
    margin-top: 40px;
    display: flex; }
    #catalog .catalog__box:last-child .catalog__title {
      font-size: 2.1rem; }
    @media (max-width: 750px) {
      #catalog .catalog__box {
        width: 100%;
        height: auto;
        flex-direction: column;
        float: none; } }
  @media (max-width: 750px) {
    #catalog .catalog__ph img {
      width: 100%; } }
  #catalog .catalog__textarea {
    padding: 32px 32px 96px;
    height: 100%;
    position: relative; }
    @media (max-width: 750px) {
      #catalog .catalog__textarea {
        padding: 20px; } }
  #catalog .catalog__title {
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 500; }
    @media (max-width: 750px) {
      #catalog .catalog__title {
        font-size: 1.8rem; } }
  #catalog .catalog__desc {
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: .02em;
    margin: 8px 0 12px; }
    @media (max-width: 750px) {
      #catalog .catalog__desc {
        font-size: 1.2rem; } }
  #catalog .catalog .border-btn {
    border-color: #004bb4;
    width: calc(100% - 64px);
    height: 64px;
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    margin: auto; }
    @media (max-width: 750px) {
      #catalog .catalog .border-btn {
        width: 100%;
        height: 50px;
        position: static; } }
    #catalog .catalog .border-btn a:hover {
      background-color: #004bb4; }
      #catalog .catalog .border-btn a:hover p {
        color: #fff; }
    #catalog .catalog .border-btn p {
      color: #004bb4;
      font-size: 1.8rem; }
      @media (max-width: 750px) {
        #catalog .catalog .border-btn p {
          font-size: 1.4rem; } }

#company .mv {
  background-image: url(images/company/company_mv-bg.jpg); }

#company .message-box:before {
  background-image: url(images/company/company_message-ph.jpg); }
  @media (max-width: 750px) {
    #company .message-box:before {
      background-position: left 0 top 20%; } }

#company .maincontent {
  margin-top: 0;
  background-color: transparent; }

#company .about {
  background-color: #d2dae6;
  padding-top: 100px;
  margin-top: 100px;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #company .about {
      min-width: 0;
      padding-top: 60px;
      margin-top: 60px; } }
  #company .about .philosophy {
    margin: -200px auto 0;
    text-align: center;
    background-color: #fff; }
    @media (max-width: 750px) {
      #company .about .philosophy {
        margin-top: -120px; } }
    #company .about .philosophy__title {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.4rem; }
      @media (max-width: 750px) {
        #company .about .philosophy__title {
          height: 60px;
          font-size: 1.8rem; } }
    #company .about .philosophy__copy {
      font-size: 3rem;
      line-height: 4.6rem;
      border-top: 1px solid #d2dae6;
      padding: 40px 0 15px;
      font-weight: 600; }
      @media (max-width: 750px) {
        #company .about .philosophy__copy {
          font-size: 2.1rem;
          line-height: 3rem;
          padding: 20px 0 10px; } }
    #company .about .philosophy__desc {
      width: 455px;
      margin: 0 auto;
      padding-bottom: 40px;
      font-size: 1.5rem;
      letter-spacing: .08em; }
      @media (max-width: 750px) {
        #company .about .philosophy__desc {
          width: 100%;
          font-size: 1.2rem;
          padding: 0 10px 30px; } }
  #company .about .service {
    margin: 80px 0 0;
    padding: 0 0 80px;
    position: relative; }
    @media (max-width: 750px) {
      #company .about .service {
        margin-top: 40px;
        padding-bottom: 40px; } }
    #company .about .service__list-title {
      font-size: 2.8rem;
      line-height: 2.8rem;
      color: #69829b; }
      @media (max-width: 750px) {
        #company .about .service__list-title {
          font-size: 2.3rem;
          margin-left: 10px; } }
    #company .about .service__list-subtitle {
      font-size: 1.6rem;
      line-height: 3rem;
      margin-top: 10px;
      color: #69829b; }
      @media (max-width: 750px) {
        #company .about .service__list-subtitle {
          font-size: 1.2rem;
          margin-left: 10px;
          margin-top: 0; } }
    #company .about .service__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px; }
      @media (max-width: 750px) {
        #company .about .service__list {
          margin: 30px auto 0;
          justify-content: space-around; } }
    #company .about .service__box {
      width: 285px;
      background-color: #fff; }
      @media (max-width: 750px) {
        #company .about .service__box {
          margin: 0 10px 15px;
          width: 100%; } }
    @media (max-width: 750px) {
      #company .about .service__ph img {
        width: 100%; } }
    #company .about .service__textarea {
      padding: 30px; }
      @media (max-width: 750px) {
        #company .about .service__textarea {
          padding: 20px; } }
    #company .about .service__title {
      font-size: 2rem;
      line-height: 2.7rem;
      font-weight: 500; }
      @media (max-width: 750px) {
        #company .about .service__title {
          font-size: 1.8rem;
          line-height: 2.8rem; } }
    #company .about .service__desc {
      font-size: 1.4rem;
      line-height: 2.6rem;
      letter-spacing: .08em;
      margin-top: 8px; }
      @media (max-width: 750px) {
        #company .about .service__desc {
          font-size: 1.2rem; } }
    #company .about .service .border-btn {
      border-color: #69829b;
      width: 180px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0; }
      @media (max-width: 750px) {
        #company .about .service .border-btn {
          position: static;
          margin-top: 15px; } }
      #company .about .service .border-btn a:hover {
        background-color: #69829b; }
        #company .about .service .border-btn a:hover p {
          color: #dce1eb; }
      #company .about .service .border-btn p {
        color: #69829b;
        font-size: 1.4rem; }

#company .link-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px; }
  @media (max-width: 750px) {
    #company .link-list {
      justify-content: space-around;
      margin-top: 50px; } }
  #company .link-list__box {
    width: 580px;
    background-color: #fff; }
    @media (max-width: 750px) {
      #company .link-list__box {
        width: 100%;
        margin-top: 15px; }
        #company .link-list__box:first-child {
          margin-top: 0; } }
  @media (max-width: 750px) {
    #company .link-list__ph img {
      width: 100%; } }
  #company .link-list__textarea {
    padding: 60px; }
    @media (max-width: 750px) {
      #company .link-list__textarea {
        padding: 30px; } }
  #company .link-list__title {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 500; }
    @media (max-width: 750px) {
      #company .link-list__title {
        font-size: 1.8rem;
        line-height: 2.6rem; } }
  #company .link-list__desc {
    font-size: 1.4rem;
    line-height: 2.8rem;
    letter-spacing: .08em;
    margin: 18px 0 0; }
    @media (max-width: 750px) {
      #company .link-list__desc {
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin: 10px 0 16px; } }
  #company .link-list .border-btn {
    display: inline-block;
    border-color: #004bb4;
    margin-top: 20px; }
    #company .link-list .border-btn a:hover {
      background-color: #004bb4; }
      #company .link-list .border-btn a:hover p {
        color: #fff; }
    #company .link-list .border-btn p {
      color: #004bb4; }

#contact .mv {
  background-image: url(images/contact/contact_mv-bg.jpg); }

#contact .message-box:before {
  background-image: url(images/contact/contact_message-ph.jpg); }
  @media (max-width: 750px) {
    #contact .message-box:before {
      background-position: left 0 top 20%; } }

#contact .maincontent {
  margin-top: 0;
  background-color: transparent;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #contact .maincontent {
      min-width: 0; } }

@media (max-width: 750px) {
  #contact .contact {
    margin-top: 40px;
    padding-bottom: 40px; } }

#contact .contact__box {
  padding: 80px 0 30px;
  background-color: #fff; }
  @media (max-width: 750px) {
    #contact .contact__box {
      padding: 30px 10px 20px; } }

#contact .contact__copy {
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #69829b;
  position: relative;
  letter-spacing: .1em;
  padding: 20px 0 40px; }
  #contact .contact__copy:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 18px auto 0;
    background-color: #69829b; }
    @media (max-width: 750px) {
      #contact .contact__copy:after {
        margin-top: 10px; } }
  @media (max-width: 750px) {
    #contact .contact__copy {
      font-size: 2.3rem; } }

#contact .contact__desc {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 25px; }
  @media (max-width: 750px) {
    #contact .contact__desc {
      font-size: 1.2rem; } }

#contact .contact .formtable {
  width: 100%;
  margin: 40px 0 30px; }
  #contact .contact .formtable__name input[type="text"] {
    width: 35%; }
    @media (max-width: 750px) {
      #contact .contact .formtable__name input[type="text"] {
        width: 70%; } }
  #contact .contact .formtable__phonetic input[type="text"] {
    width: 35%; }
    @media (max-width: 750px) {
      #contact .contact .formtable__phonetic input[type="text"] {
        width: 70%; } }
  #contact .contact .formtable__email input[type="text"] {
    width: 55%; }
    @media (max-width: 750px) {
      #contact .contact .formtable__email input[type="text"] {
        width: 80%; } }
  #contact .contact .formtable__tel input[type="text"] {
    width: 30%; }
    @media (max-width: 750px) {
      #contact .contact .formtable__tel input[type="text"] {
        width: 75%; } }
  #contact .contact .formtable__address input[type="text"] {
    width: 80%; }
    @media (max-width: 750px) {
      #contact .contact .formtable__address input[type="text"] {
        width: 100%; } }

#contact .contact tr {
  border-bottom: 1px solid #ddd; }
  #contact .contact tr:first-child {
    border-top: 1px solid #ddd; }

#contact .contact th {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 500;
  width: 200px;
  padding: 30px 0;
  vertical-align: top; }
  @media (max-width: 750px) {
    #contact .contact th {
      width: 100%;
      display: block;
      font-size: 1.4rem;
      padding: 20px 0 0; } }

#contact .contact td {
  padding: 30px 0 30px 30px; }
  @media (max-width: 750px) {
    #contact .contact td {
      width: 100%;
      display: block;
      padding: 10px 0 20px; } }

#contact .contact input[type="text"] {
  background-color: #fff;
  border: 1px solid #91a5be;
  padding: 10px;
  width: 100%; }
  #contact .contact input[type="text"]:focus {
    box-shadow: 0 0 8px rgba(105, 130, 155, 0.3); }

#contact .contact textarea {
  background-color: #fff;
  border: 1px solid #91a5be;
  padding: 10px;
  width: 100% !important;
  height: 160px !important; }
  #contact .contact textarea:focus {
    box-shadow: 0 0 8px rgba(105, 130, 155, 0.3); }

#contact .contact .btn-list {
  display: flex;
  justify-content: center; }
  #contact .contact .btn-list .border-btn {
    margin: 0 10px; }

#contact .contact .border-btn {
  border-color: #004bb4;
  display: flex;
  justify-content: center;
  align-items: center; }
  #contact .contact .border-btn.cancel input {
    background-color: #fff;
    color: #004bb4; }
    #contact .contact .border-btn.cancel input:hover {
      background-color: #004bb4;
      color: #fff; }
  #contact .contact .border-btn p {
    display: block;
    width: 100%;
    height: 100%; }
  #contact .contact .border-btn input {
    font-size: 1.8rem;
    line-height: 1.8rem;
    width: 100%;
    height: 100%;
    display: block;
    color: #fff;
    background-color: #004bb4;
    cursor: pointer;
    transition: .3s ease; }
    @media (max-width: 750px) {
      #contact .contact .border-btn input {
        font-size: 1.4rem; } }
    #contact .contact .border-btn input:hover {
      background-color: transparent;
      color: #004bb4; }

#guideline .mv {
  background-image: url(images/guideline/guideline_mv-bg.jpg); }

#guideline .maincontent {
  margin-top: 0;
  background-color: transparent; }

#guideline .guideline {
  background-color: #fff;
  padding: 60px;
  margin: 80px 0; }
  @media (max-width: 750px) {
    #guideline .guideline {
      padding: 20px;
      margin: 30px 0; } }
  #guideline .guideline__title {
    text-align: center;
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 500;
    margin-bottom: 35px; }
    @media (max-width: 750px) {
      #guideline .guideline__title {
        font-size: 2.2rem;
        margin-bottom: 15px; } }
  #guideline .guideline table {
    width: 100%; }
  #guideline .guideline tr {
    width: 100%;
    padding: 18px 0;
    display: block;
    border-bottom: 1px solid #e0e0e0; }
    @media (max-width: 750px) {
      #guideline .guideline tr {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px; } }
    #guideline .guideline tr:first-child {
      border-top: 1px solid #e0e0e0; }
  #guideline .guideline th {
    width: 220px;
    font-weight: 500;
    font-size: 1.6rem;
    padding: 0 0 0 10px;
    vertical-align: top;
    text-align: left;
    line-height: 2.2rem; }
    @media (max-width: 750px) {
      #guideline .guideline th {
        padding: 0;
        font-size: 1.4rem;
        flex: 0 0 auto; } }
  #guideline .guideline td {
    font-size: 1.4rem;
    line-height: 2.2rem; }
    #guideline .guideline td > ul > li {
      margin-top: 6px; }
      #guideline .guideline td > ul > li:first-child {
        margin-top: 0; }
    #guideline .guideline td ul ul {
      font-size: 1.2rem; }
      #guideline .guideline td ul ul li p:before {
        content: '･'; }
    @media (max-width: 750px) {
      #guideline .guideline td {
        font-size: 1.2rem;
        line-height: 1.8rem; } }

#guideline .flow {
  background-color: #d2dae6;
  padding: 80px 0;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #guideline .flow {
      padding: 30px 0 0;
      min-width: 0; } }
  #guideline .flow__title {
    font-size: 2.8rem;
    line-height: 2.8rem;
    color: #69829b; }
    @media (max-width: 750px) {
      #guideline .flow__title {
        font-size: 2.3rem;
        margin-left: 10px; } }
  #guideline .flow__subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 10px;
    color: #69829b; }
    @media (max-width: 750px) {
      #guideline .flow__subtitle {
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0; } }
  #guideline .flow__step-number {
    padding-bottom: 25px; }
    @media (max-width: 750px) {
      #guideline .flow__step-number {
        padding-bottom: 20px; } }
  #guideline .flow__name {
    writing-mode: vertical-rl;
    font-size: 2.8rem;
    line-height: 3rem;
    color: #69829b; }
    @media (max-width: 750px) {
      #guideline .flow__name {
        font-size: 2.6rem;
        writing-mode: horizontal-tb; } }
  #guideline .flow ol {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; }
    @media (max-width: 750px) {
      #guideline .flow ol {
        flex-direction: column; } }
  #guideline .flow li {
    background-color: #fff;
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    position: relative; }
    @media (max-width: 750px) {
      #guideline .flow li {
        height: 180px;
        padding-top: 45px; } }
    #guideline .flow li:first-child:before {
      content: none; }
    #guideline .flow li:before {
      content: '';
      display: block;
      background: url(images/guideline/whitearrow.png) left 0 no-repeat;
      background-size: cover;
      height: 100%;
      width: 50px;
      position: absolute;
      top: 0;
      left: -25px; }
      @media (max-width: 750px) {
        #guideline .flow li:before {
          background: url(images/guideline/whitearrow_sp.png) center 0 no-repeat;
          width: 100%;
          height: 87px;
          top: -70px;
          left: auto; } }

#guideline .application-btn {
  background-color: #f0f2f6;
  margin-top: 80px;
  padding: 40px 0 50px; }
  @media (max-width: 750px) {
    #guideline .application-btn {
      margin-top: 30px;
      padding: 25px 0; } }
  #guideline .application-btn__title {
    font-size: 2.8rem;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 22px; }
    @media (max-width: 750px) {
      #guideline .application-btn__title {
        font-size: 1.8rem;
        margin-bottom: 8px; } }
  #guideline .application-btn .border-btn {
    border-color: #004bb4; }
    #guideline .application-btn .border-btn a:hover {
      background-color: #004bb4; }
      #guideline .application-btn .border-btn a:hover p {
        color: #fff; }
    #guideline .application-btn .border-btn p {
      color: #004bb4; }

#justlogi .mv {
  background-image: url(images/justlogi/justlogi_mv-bg.jpg); }

#justlogi .maincontent {
  margin-top: 0;
  background-color: transparent; }

#justlogi .message-box:before {
  background-image: url(images/justlogi/justlogi_message-ph1.jpg); }
  @media (max-width: 750px) {
    #justlogi .message-box:before {
      background-position: left 0 top 20%; } }

#justlogi .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0;
  min-height: 280px; }
  @media (max-width: 750px) {
    #justlogi .message-box__reverse {
      margin: 30px 0 0;
      padding: 0;
      min-height: 0; } }
  #justlogi .message-box__reverse .l-inner {
    position: relative; }
  #justlogi .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #justlogi .message-box__reverse .message-box__textarea {
        width: 100%;
        padding-top: 25px; } }
  #justlogi .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #justlogi .message-box__reverse:before {
    background-image: url(images/justlogi/justlogi_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }

#justlogi .map-area {
  padding: 100px 0 100px 130px;
  background-color: #fff;
  position: relative;
  margin: 0 0 230px; }
  @media (max-width: 750px) {
    #justlogi .map-area {
      padding: 50px 20px;
      margin: 0; } }
  #justlogi .map-area:before {
    content: '';
    display: block;
    position: absolute;
    width: 545px;
    height: 465px;
    background: url(images/justlogi/map.png) left top no-repeat;
    background-size: 100% auto;
    right: 40px;
    top: 40px; }
    @media (max-width: 750px) {
      #justlogi .map-area:before {
        width: 75vw;
        height: 64.3vw;
        position: static;
        margin: auto;
        max-width: 420px;
        max-height: 359px; } }
  #justlogi .map-area__desc {
    font-size: 2.8rem;
    line-height: 4.6rem; }
    @media (max-width: 750px) {
      #justlogi .map-area__desc {
        font-size: 1.6rem;
        line-height: 1.6;
        margin: 30px auto 0;
        text-align: center; } }

#justlogi .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #justlogi .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #justlogi .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #justlogi .btn-list li {
        margin: 5px; } }
  #justlogi .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #justlogi .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #justlogi .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #justlogi .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #justlogi .btn-list .border-btn p {
      color: #004bb4; }

#losscon .mv {
  background-image: url(images/losscon/losscon_mv-bg.jpg); }

#losscon .maincontent {
  margin-top: 0;
  background-color: transparent; }

#losscon .catalog {
  background-color: #d2dae6;
  padding: 80px 0;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #losscon .catalog {
      padding: 30px 0;
      min-width: 0; } }
  #losscon .catalog__list-title {
    font-size: 2.8rem;
    line-height: 2.8rem;
    color: #69829b; }
    @media (max-width: 750px) {
      #losscon .catalog__list-title {
        font-size: 2.3rem;
        margin-left: 10px; } }
  #losscon .catalog__list-subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 10px;
    color: #69829b; }
    @media (max-width: 750px) {
      #losscon .catalog__list-subtitle {
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0; } }
  #losscon .catalog__list {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; }
    @media (max-width: 750px) {
      #losscon .catalog__list {
        flex-direction: column; } }
  #losscon .catalog__box {
    background-color: #fff;
    width: 285px; }
    @media (max-width: 750px) {
      #losscon .catalog__box {
        width: 100%;
        margin-top: 20px; }
        #losscon .catalog__box:first-child {
          margin-top: 0; } }
  @media (max-width: 750px) {
    #losscon .catalog__ph img {
      width: 100%; } }
  #losscon .catalog__textarea {
    padding: 0 30px; }
    @media (max-width: 750px) {
      #losscon .catalog__textarea {
        padding: 0 20px; } }
  #losscon .catalog__title {
    font-size: 1.8rem;
    line-height: 4.2rem;
    margin-top: 12px; }
    @media (max-width: 750px) {
      #losscon .catalog__title {
        font-size: 1.6rem; } }
  #losscon .catalog__desc {
    font-size: 1.3rem;
    line-height: 2rem;
    color: #505050;
    min-height: 60px; }
    @media (max-width: 750px) {
      #losscon .catalog__desc {
        font-size: 1.2rem;
        min-height: 0; } }
  #losscon .catalog__btn {
    width: 100%;
    border-color: #004bb4;
    margin: 20px 0; }
    @media (max-width: 750px) {
      #losscon .catalog__btn {
        margin: 15px auto; } }
    #losscon .catalog__btn a:hover {
      background-color: #004bb4; }
      #losscon .catalog__btn a:hover p {
        color: #fff; }
    #losscon .catalog__btn p {
      color: #004bb4; }
  #losscon .catalog__download {
    background-color: #f8f8f8;
    margin: 5px;
    border: 1px solid #eeeeee; }
    #losscon .catalog__download a {
      padding: 10px; }
      #losscon .catalog__download a:before {
        content: url(images/common/icon_pdf.png);
        display: inline-block;
        line-height: 0;
        vertical-align: middle; }
      #losscon .catalog__download a:hover {
        opacity: .7; }
      #losscon .catalog__download a p {
        display: inline-block;
        font-size: 1.3rem;
        line-height: 4rem;
        color: #000; }
        @media (max-width: 750px) {
          #losscon .catalog__download a p {
            font-size: 1.2rem; } }

#losscon .sscenter {
  margin-top: 80px; }
  #losscon .sscenter .phbox {
    height: 380px;
    background: url(images/losscon/losscon_sscenter-bg.jpg) center 0 no-repeat;
    background-size: cover;
    color: #fff;
    letter-spacing: .08em;
    text-align: center;
    padding: 90px 300px 0; }
    @media (max-width: 750px) {
      #losscon .sscenter .phbox {
        padding: 60px 10px;
        height: auto; } }
    #losscon .sscenter .phbox__title {
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: #fff;
      width: 260px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 1px 0;
      border-style: solid;
      border-color: #fff;
      margin: 0 auto; }
      @media (max-width: 750px) {
        #losscon .sscenter .phbox__title {
          width: 225px;
          font-size: 1.6rem;
          height: 50px; } }
    #losscon .sscenter .phbox__copy {
      font-size: 4rem;
      line-height: 4.6rem;
      margin: 50px 0 0; }
      @media (max-width: 750px) {
        #losscon .sscenter .phbox__copy {
          font-size: 2.1rem;
          margin: 15px 0 0; } }
    #losscon .sscenter .phbox__desc {
      font-size: 1.5rem;
      line-height: 3rem;
      font-weight: 400; }
      @media (max-width: 750px) {
        #losscon .sscenter .phbox__desc {
          font-size: 1.2rem;
          line-height: 2rem; } }
  #losscon .sscenter .article {
    background-color: #fff;
    padding: 60px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 750px) {
      #losscon .sscenter .article {
        flex-direction: column;
        padding: 30px 10px; } }
    @media (max-width: 750px) {
      #losscon .sscenter .article__ph {
        width: 100%;
        max-width: 380px;
        margin: 0 auto; }
        #losscon .sscenter .article__ph img {
          width: 100%; } }
    #losscon .sscenter .article__textarea {
      width: 560px;
      padding: 25px 0 0; }
      @media (max-width: 750px) {
        #losscon .sscenter .article__textarea {
          width: 100%;
          padding: 0; } }
    #losscon .sscenter .article__copy {
      font-size: 2.7rem;
      line-height: 3.8rem;
      font-weight: 500; }
      @media (max-width: 750px) {
        #losscon .sscenter .article__copy {
          font-size: 2.1rem;
          line-height: 3rem;
          max-width: 380px;
          margin: 20px auto; } }
    #losscon .sscenter .article__desc {
      font-size: 1.6rem;
      margin-top: 18px; }
      @media (max-width: 750px) {
        #losscon .sscenter .article__desc {
          font-size: 1.2rem;
          max-width: 380px;
          margin: 0 auto; } }

#machine .mv {
  background-image: url(images/machine/machine_mv-bg.jpg); }

#machine .maincontent {
  margin-top: 0;
  background-color: transparent; }

#machine .message-box:before {
  background-image: url(images/machine/machine_message-ph1.jpg); }
  @media (max-width: 750px) {
    #machine .message-box:before {
      background-position: left 0 top 20%; } }

#machine .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0;
  min-height: 280px; }
  @media (max-width: 750px) {
    #machine .message-box__reverse {
      margin: 30px 0 0;
      padding: 0;
      min-height: 0; } }
  #machine .message-box__reverse .l-inner {
    position: relative; }
  #machine .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #machine .message-box__reverse .message-box__textarea {
        width: 100%;
        padding-top: 25px; } }
  #machine .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #machine .message-box__reverse:before {
    background-image: url(images/machine/machine_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }
    @media (max-width: 750px) {
      #machine .message-box__reverse:before {
        height: 240px; } }

#machine .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #machine .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #machine .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #machine .btn-list li {
        margin: 5px; } }
  #machine .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #machine .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #machine .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #machine .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #machine .btn-list .border-btn p {
      color: #004bb4; }

#message .mv {
  background-image: url(images/message/message_mv-bg.jpg); }

#message .maincontent {
  margin-top: 0;
  background-color: transparent; }

#message .message-box {
  padding-top: 30px; }
  #message .message-box__copy {
    letter-spacing: 3.3px; }
  #message .message-box__desc p {
    padding-top: 23px;
    line-height: 2.1; }
  #message .message-box:before {
    content: none;
    background-image: url(images/message/message_message-ph1.jpg); }
    @media (max-width: 750px) {
      #message .message-box:before {
        background-position: left 40% top 20%; } }
  #message .message-box__large {
    padding-bottom: 40px; }
    @media (max-width: 750px) {
      #message .message-box__large {
        padding: 30px 0; } }
    #message .message-box__large .message-box__desc {
      width: 100%; }
    #message .message-box__large:before {
      background-image: url(images/message/message_message-ph2.jpg); }
  #message .message-box__reverse .message-box__copy {
    margin-left: auto;
    padding-top: 0; }
    @media (max-width: 750px) {
      #message .message-box__reverse .message-box__copy {
        margin-top: 20px; } }
  #message .message-box__reverse .message-box__desc {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #message .message-box__reverse .message-box__desc {
        width: 100%; } }
  #message .message-box__reverse:before {
    background-image: url(images/message/message_message-ph3.jpg);
    right: auto;
    left: 0; }

#message .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #message .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #message .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #message .btn-list li {
        margin: 5px; } }
  #message .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #message .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #message .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #message .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #message .btn-list .border-btn p {
      color: #004bb4; }

#overview .mv {
  background-image: url(images/overview/overview_mv-bg.jpg); }

#overview .maincontent {
  margin-top: 0;
  background-color: transparent; }

#overview .overview {
  background-color: #fff;
  padding: 60px;
  margin: 80px 0; }
  @media (max-width: 750px) {
    #overview .overview {
      padding: 20px;
      margin: 30px 0; } }
  #overview .overview__title {
    text-align: center;
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 500;
    margin-bottom: 35px; }
    @media (max-width: 750px) {
      #overview .overview__title {
        font-size: 2.2rem;
        margin-bottom: 15px; } }
  #overview .overview table {
    width: 100%; }
  #overview .overview tr {
    width: 100%;
    padding: 18px 0;
    display: block;
    border-bottom: 1px solid #e0e0e0; }
    @media (max-width: 750px) {
      #overview .overview tr {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px; } }
    #overview .overview tr:first-child {
      border-top: 1px solid #e0e0e0; }
  #overview .overview th {
    width: 220px;
    font-weight: 500;
    font-size: 1.6rem;
    padding: 0 0 0 10px;
    vertical-align: top;
    text-align: left;
    line-height: 2.2rem; }
    #overview .overview th span {
      font-size: 1.2rem;
      line-height: 1.4rem; }
    @media (max-width: 750px) {
      #overview .overview th {
        padding: 0;
        font-size: 1.4rem;
        flex: 0 0 auto; } }
  #overview .overview td {
    font-size: 1.4rem;
    line-height: 2.2rem; }
    #overview .overview td > ul > li {
      margin-top: 6px; }
      #overview .overview td > ul > li:first-child {
        margin-top: 0; }
    #overview .overview td ul ul {
      font-size: 1.2rem; }
      #overview .overview td ul ul li p:before {
        content: '･'; }
    @media (max-width: 750px) {
      #overview .overview td {
        font-size: 1.2rem;
        line-height: 1.8rem; } }

#overview .history {
  background-color: #d2dae6;
  padding: 80px 0 50px;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #overview .history {
      padding: 30px 10px;
      min-width: 0; } }
  #overview .history__list-title {
    font-size: 2.8rem;
    line-height: 2.8rem;
    color: #69829b; }
    @media (max-width: 750px) {
      #overview .history__list-title {
        font-size: 2.3rem;
        margin-left: 10px; } }
  #overview .history__list-subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 10px;
    color: #69829b; }
    @media (max-width: 750px) {
      #overview .history__list-subtitle {
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0; } }
  #overview .history__list {
    position: relative;
    margin-top: 20px; }
    #overview .history__list:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: #69829b;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1; }
  #overview .history li {
    background-color: #fff; }
    @media (max-width: 750px) {
      #overview .history li {
        margin-bottom: 20px;
        width: 100%;
        height: auto;
        padding: 20px 0; } }
  #overview .history__box-l {
    width: 550px;
    height: 114px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    margin: 0 0 30px; }
    #overview .history__box-l:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-width: 57px 0 57px 40px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 0;
      right: -40px; }
      @media (max-width: 750px) {
        #overview .history__box-l:after {
          content: none; } }
  #overview .history__box-r {
    width: 550px;
    height: 114px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: absolute;
    z-index: 10;
    right: 0;
    margin-top: -74px; }
    @media (max-width: 750px) {
      #overview .history__box-r {
        position: relative;
        margin: 0;
        flex-direction: row;
        justify-content: flex-start; } }
    #overview .history__box-r:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-width: 57px 40px 57px 0;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      top: 0;
      left: -40px; }
      @media (max-width: 750px) {
        #overview .history__box-r:after {
          content: none; } }
    #overview .history__box-r .history__textarea {
      padding: 0 0 0 20px; }
      @media (max-width: 750px) {
        #overview .history__box-r .history__textarea {
          padding: 0; } }
  #overview .history__box-photo {
    width: 100%;
    height: 214px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-bottom: 30px; }
    @media (max-width: 750px) {
      #overview .history__box-photo {
        height: 150px;
        padding-bottom: 0 !important;
        flex-wrap: wrap; } }
  #overview .history__box-large {
    width: 100%;
    height: 114px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-bottom: 30px; }
  #overview .history__textarea {
    flex: 1 1 0; }
  #overview .history__year {
    font-family: 'Ropa Sans', sans-serif;
    color: #69829b;
    font-size: 5.6rem;
    line-height: 3.4rem;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0 30px; }
    @media (max-width: 750px) {
      #overview .history__year {
        width: 24vw;
        max-width: 90px;
        margin: 0 10px;
        font-size: 4.6rem;
        line-height: 3rem;
        letter-spacing: -0.1rem; } }
  #overview .history__ph {
    margin-left: auto; }
    @media (max-width: 750px) {
      #overview .history__ph {
        margin-left: 0;
        margin-top: 20px;
        width: 100%; }
        #overview .history__ph img {
          width: 100%; } }
  #overview .history__title {
    font-size: 1.8rem;
    line-height: 1.8rem;
    letter-spacing: .08em; }
    @media (max-width: 750px) {
      #overview .history__title {
        font-size: 1.2rem; } }
  #overview .history__desc {
    font-size: 1.4rem;
    line-height: 1.4rem;
    letter-spacing: .08em;
    margin-top: 4px; }
    @media (max-width: 750px) {
      #overview .history__desc {
        font-size: 1.2rem; } }

#overview .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  margin-top: 80px; }
  @media (max-width: 750px) {
    #overview .btn-list {
      flex-wrap: wrap;
      padding: 20px 0;
      margin-top: 40px; } }
  #overview .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #overview .btn-list li {
        margin: 5px 10px;
        flex: 1 1 280px;
        width: 100%; } }
  #overview .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #overview .btn-list .border-btn {
        width: 100%;
        height: 60px; } }
    #overview .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #overview .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #overview .btn-list .border-btn p {
      color: #004bb4; }

#PPS .mv {
  background-image: url(images/PPS/PPS_mv-bg.jpg); }

#PPS .maincontent {
  margin-top: 0;
  background-color: transparent; }

#PPS .message-box:before {
  background-image: url(images/PPS/PPS_message-ph1.jpg); }
  @media (max-width: 750px) {
    #PPS .message-box:before {
      background-position: left 0 top 20%; } }

#PPS .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0;
  min-height: 280px; }
  @media (max-width: 750px) {
    #PPS .message-box__reverse {
      margin: 30px 0 0;
      padding: 0;
      min-height: 0; } }
  #PPS .message-box__reverse .l-inner {
    position: relative; }
  #PPS .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #PPS .message-box__reverse .message-box__textarea {
        width: 100%;
        padding-top: 25px; } }
  #PPS .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #PPS .message-box__reverse:before {
    background-image: url(images/PPS/PPS_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }
    @media (max-width: 750px) {
      #PPS .message-box__reverse:before {
        height: 240px; } }

#PPS .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #PPS .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #PPS .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #PPS .btn-list li {
        margin: 5px; } }
  #PPS .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #PPS .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #PPS .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #PPS .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #PPS .btn-list .border-btn p {
      color: #004bb4; }

#product .mv {
  background-image: url(images/product/product_mv-bg.jpg); }

#product .maincontent {
  margin-top: 0;
  background-color: transparent; }

#product .message-box:before {
  background-image: url(images/product/product_message-ph1.jpg); }
  @media (max-width: 750px) {
    #product .message-box:before {
      background-position: left 0 top 20%; } }

#product .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0;
  min-height: 280px; }
  @media (max-width: 750px) {
    #product .message-box__reverse {
      margin: 30px 0 0;
      padding: 0;
      min-height: 0; } }
  #product .message-box__reverse .l-inner {
    position: relative; }
  #product .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #product .message-box__reverse .message-box__textarea {
        width: 100%;
        padding-top: 25px; } }
  #product .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #product .message-box__reverse:before {
    background-image: url(images/product/product_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }
    @media (max-width: 750px) {
      #product .message-box__reverse:before {
        height: 240px; } }

#product .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #product .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #product .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #product .btn-list li {
        margin: 5px; } }
  #product .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #product .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #product .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #product .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #product .btn-list .border-btn p {
      color: #004bb4; }

#recruit .mv {
  background-image: url(images/recruit/recruit_mv-bg.jpg); }

#recruit .message-box {
  padding: 80px 0 60px; }
  @media (max-width: 750px) {
    #recruit .message-box {
      padding-top: 20px; } }
  #recruit .message-box:before {
    content: none;
    background-image: url(images/recruit/recruit_message-ph.jpg); }
    @media (max-width: 750px) {
      #recruit .message-box:before {
        background-position: left 0 top 20%; } }
  #recruit .message-box__copy {
    padding-bottom: 10px; }
  #recruit .message-box__desc {
    width: 100%; }
  #recruit .message-box__role {
    border-top: none;
    margin-top: 10px;
    padding-right: 0; }
  #recruit .message-box__name {
    padding-right: 0; }

#recruit .maincontent {
  margin-top: 0;
  background-color: transparent; }

#recruit .workstyle {
  background-color: #d2dae6;
  padding: 80px 0;
  min-width: 1200px; }
  @media (max-width: 750px) {
    #recruit .workstyle {
      padding: 0 0 40px;
      min-width: 0; } }
  #recruit .workstyle__list-title {
    font-size: 2.8rem;
    line-height: 2.8rem;
    color: #69829b; }
    #recruit .workstyle__list-title.interview {
      padding-top: 50px; }
      @media (max-width: 750px) {
        #recruit .workstyle__list-title.interview {
          padding-top: 70px; } }
    @media (max-width: 750px) {
      #recruit .workstyle__list-title {
        font-size: 2.3rem;
        margin-left: 10px;
        padding: 30px 0 0; } }
  #recruit .workstyle__list-subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 10px;
    color: #69829b; }
    @media (max-width: 750px) {
      #recruit .workstyle__list-subtitle {
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: 0;
        padding: 0 0 20px; } }
  #recruit .workstyle__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px; }
    @media (max-width: 750px) {
      #recruit .workstyle__list {
        flex-direction: column;
        margin-top: 0; } }
    @media (min-width: 751px) {
      #recruit .workstyle__list__large .workstyle__box {
        width: 100%;
        max-height: 420px;
        display: flex;
        margin-bottom: 40px; } }
    @media (max-width: 750px) {
      #recruit .workstyle__list__large .workstyle__box {
        margin-top: 0; } }
  #recruit .workstyle__box {
    background-color: #fff;
    width: 580px;
    position: relative; }
    @media (max-width: 750px) {
      #recruit .workstyle__box {
        width: 100%; } }
  @media (max-width: 750px) {
    #recruit .workstyle__ph img {
      width: 100%; } }
  #recruit .workstyle__textarea {
    padding: 85px 60px 130px; }
    @media (max-width: 750px) {
      #recruit .workstyle__textarea {
        padding: 15px 15px 70px; } }
  #recruit .workstyle__copy {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 500;
    padding: 0 0 10px; }
    @media (max-width: 750px) {
      #recruit .workstyle__copy {
        font-size: 1.6rem;
        line-height: 2.8rem; } }
  #recruit .workstyle__subtitle {
    font-size: 2.2rem;
    line-height: 1;
    font-weight: 400;
    padding: 30px 0 5px;
    display: inline-block;
    color: #69829b;
    border-bottom: 1px solid #69829b; }
    @media (max-width: 750px) {
      #recruit .workstyle__subtitle {
        font-size: 1.6rem;
        padding: 10px 0 5px; } }
  #recruit .workstyle__desc {
    font-size: 1.5rem;
    line-height: 3.1rem;
    margin-top: 10px;
    letter-spacing: .08em; }
    @media (max-width: 750px) {
      #recruit .workstyle__desc {
        font-size: 1.2rem; } }
  #recruit .workstyle__name {
    font-size: 1.8rem;
    text-align: right;
    position: absolute;
    bottom: 60px;
    right: 60px; }
    #recruit .workstyle__name span {
      font-size: 1.4rem;
      margin-right: 15px; }
    @media (max-width: 750px) {
      #recruit .workstyle__name {
        right: 15px;
        bottom: 20px;
        font-size: 1.4rem; }
        #recruit .workstyle__name span {
          font-size: 1.1rem; } }

#recruit .btn-list {
  display: flex;
  background-color: #f0f2f6;
  text-align: center;
  margin-top: 80px; }
  @media (max-width: 750px) {
    #recruit .btn-list {
      flex-direction: column;
      margin-top: 0; } }
  #recruit .btn-list li {
    width: 50%;
    padding: 35px 0 45px;
    border-right: 1px solid #dce1eb; }
    @media (max-width: 750px) {
      #recruit .btn-list li {
        width: 100%;
        padding: 16px 0 20px;
        border-right: none; } }
    #recruit .btn-list li:nth-child(2n) {
      border-right: none;
      border-left: 1px solid #dce1eb; }
      @media (max-width: 750px) {
        #recruit .btn-list li:nth-child(2n) {
          border-left: none;
          border-top: 2px solid #dce1eb; } }
  #recruit .btn-list__title {
    font-size: 2.8rem;
    line-height: 3rem;
    margin-bottom: 18px; }
    @media (max-width: 750px) {
      #recruit .btn-list__title {
        font-size: 1.8rem;
        margin-bottom: 6px; } }
  #recruit .btn-list .border-btn {
    border-color: #004bb4; }
    #recruit .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #recruit .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #recruit .btn-list .border-btn p {
      color: #004bb4; }

#service .mv {
  background-image: url(images/service/service_mv-bg.jpg); }

#service .maincontent {
  margin-top: 0;
  background-color: transparent; }

#service .service__list {
  display: flex;
  flex-wrap: wrap;
  margin: 50px -10px 0; }
  @media (max-width: 750px) {
    #service .service__list {
      margin: 30px auto 0;
      justify-content: space-around; } }

#service .service__box {
  width: 285px;
  background-color: #fff;
  margin: 30px 10px 0; }
  @media (max-width: 750px) {
    #service .service__box {
      margin: 0 10px 15px;
      width: 100%; } }

@media (max-width: 750px) {
  #service .service__ph img {
    width: 100%; } }

#service .service__textarea {
  padding: 32px; }
  @media (max-width: 750px) {
    #service .service__textarea {
      padding: 20px; } }

#service .service__title {
  font-size: 2.2rem;
  line-height: 1.4;
  font-weight: 500; }
  @media (max-width: 750px) {
    #service .service__title {
      font-size: 2rem; } }

#service .service__desc {
  font-size: 1.4rem;
  line-height: 2.8rem;
  letter-spacing: .02em;
  margin: 12px 0 24px; }
  @media (max-width: 750px) {
    #service .service__desc {
      font-size: 1.2rem;
      line-height: 2.4rem;
      margin: 6px 0 12px; } }

#service .service .border-btn {
  border-color: #004bb4;
  width: 180px;
  height: 50px;
  display: inline-block; }
  @media (max-width: 750px) {
    #service .service .border-btn {
      width: 160px;
      height: 40px; } }
  #service .service .border-btn a:hover {
    background-color: #004bb4; }
    #service .service .border-btn a:hover p {
      color: #f0f2f6; }
  #service .service .border-btn p {
    color: #004bb4;
    font-size: 1.4rem; }
    @media (max-width: 750px) {
      #service .service .border-btn p {
        font-size: 1.2rem; } }

#shop .mv {
  background-image: url(images/shop/shop_mv-bg.jpg); }

#shop .maincontent {
  margin-top: 0;
  background-color: transparent; }

#shop .message-box:before {
  background-image: url(images/shop/shop_message-ph1.jpg); }
  @media (max-width: 750px) {
    #shop .message-box:before {
      background-position: left 0 top 20%; } }

#shop .message-box__reverse {
  padding: 0 0 80px;
  margin: 80px 0 0 0; }
  @media (max-width: 750px) {
    #shop .message-box__reverse {
      margin: 30px 0 0;
      padding: 0; } }
  #shop .message-box__reverse .l-inner {
    position: relative; }
  #shop .message-box__reverse .message-box__textarea {
    width: 500px;
    margin-left: auto; }
    @media (max-width: 750px) {
      #shop .message-box__reverse .message-box__textarea {
        width: 100%; } }
  #shop .message-box__reverse .message-box__copy {
    padding-top: 0; }
  #shop .message-box__reverse:before {
    background-image: url(images/shop/shop_message-ph2.jpg);
    right: auto;
    left: 0;
    height: 380px; }

#shop .btn-list {
  background-color: #f0f2f6;
  display: flex;
  justify-content: center;
  padding: 40px 0; }
  @media (max-width: 750px) {
    #shop .btn-list {
      flex-wrap: wrap;
      padding: 20px 0; } }
  #shop .btn-list li {
    margin: 0 10px; }
    @media (max-width: 750px) {
      #shop .btn-list li {
        margin: 5px; } }
  #shop .btn-list .border-btn {
    border-color: #004bb4; }
    @media (max-width: 750px) {
      #shop .btn-list .border-btn {
        width: 280px;
        height: 60px; } }
    #shop .btn-list .border-btn a:hover {
      background-color: #004bb4; }
      #shop .btn-list .border-btn a:hover p {
        color: #f0f2f6; }
    #shop .btn-list .border-btn p {
      color: #004bb4; }

#Top .mv {
  position: relative; }
  #Top .mv:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(images/top/top_mv-loading.jpg) 50% 50% no-repeat;
    background-size: cover;
    animation: loading 1s ease 2s;
    animation-fill-mode: forwards; }
    @media (max-width: 750px) {
      #Top .mv:before {
        background-image: url(images/top/top_mv-loading_sp.jpg); } }

.mv {
  background-position: center center;
  background-size: cover;
  min-width: 1200px;
  height: 760px;
  padding: 40px 0;
  text-align: center;
  color: #fff; }
  @media (max-width: 750px) {
    .mv {
      min-width: 0;
      max-width: 100vw;
      min-height: 350px;
      max-height: 405px;
      height: 84vw;
      padding: 20px 0;
      background-size: auto 100%; } }
  .mv__txt {
    background-color: rgba(40, 55, 70, 0.35);
    max-width: 1520px;
    margin: 0 auto;
    height: 100%;
    display: block; }
    @media (max-width: 750px) {
      .mv__txt {
        margin: 0 10px; } }
  .mv__title {
    padding-top: 180px; }
    @media (max-width: 750px) {
      .mv__title {
        margin: 0 auto;
        padding: 40px 5vw 0;
        max-width: 462px;
        box-sizing: content-box; } }
  .mv__copy {
    font-size: 4.2rem;
    font-weight: 400;
    padding-top: 50px; }
    @media (max-width: 750px) {
      .mv__copy {
        font-size: 2.1rem;
        line-height: 1rem;
        padding-top: 28px; } }
  .mv__desc {
    font-size: 1.8rem;
    line-height: 3.4rem;
    font-weight: 200;
    padding: 8px 0 50px; }
    @media (max-width: 750px) {
      .mv__desc {
        font-size: 1.2rem;
        line-height: 2.1rem;
        padding: 17px 0; } }

.maincontent {
  position: relative;
  background-color: #fff;
  margin: 90px 0 0 0;
  position: relative; }
  @media (max-width: 750px) {
    .maincontent {
      margin: 45px 10px 0; } }

.tab-btn {
  position: absolute;
  top: -50px;
  width: 100%;
  padding: 0 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  z-index: 20; }
  @media (max-width: 750px) {
    .tab-btn {
      padding: 0 10px;
      top: -25px; } }
  .tab-btn .active {
    background-color: #004bb4;
    cursor: default;
    position: relative; }
    .tab-btn .active:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 18px 0 18px;
      border-color: #004bb4 transparent transparent transparent;
      position: absolute;
      bottom: -24px;
      left: 0;
      right: 0;
      margin: auto; }
      @media (max-width: 750px) {
        .tab-btn .active:after {
          border-width: 12px 10px 0 10px;
          bottom: -12px; } }
  .tab-btn > div {
    width: 49%;
    background-color: #91a5be;
    color: #fff;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s ease; }
    @media (max-width: 750px) {
      .tab-btn > div {
        height: 50px; } }
    .tab-btn > div:hover {
      background-color: #004bb4; }
    .tab-btn > div p {
      font-size: 2.8rem;
      position: relative;
      width: 100%; }
      @media (max-width: 750px) {
        .tab-btn > div p {
          font-size: 1.4rem; } }
      .tab-btn > div p:before {
        content: '';
        display: block;
        width: 40px;
        height: 50px;
        position: absolute;
        left: 50px;
        top: 0;
        bottom: 0;
        margin: auto; }
        @media (max-width: 750px) {
          .tab-btn > div p:before {
            background-size: 100%;
            width: 20px;
            height: 25px;
            left: calc(2% + 5px); } }
  .tab-btn__overview p:before {
    background: url(images/common/icon_company.png) center 0 no-repeat; }
  .tab-btn__recruit p:before {
    background: url(images/common/icon_human.png) center 0 no-repeat; }

#slide-content {
  position: relative;
  z-index: 10; }

.overview-grp {
  width: 100%;
  padding-top: 100px;
  text-align: center;
  transform-origin: center center -50vw;
  transition: all 1s ease 0s;
  top: 0;
  z-index: 10; }
  @media (max-width: 750px) {
    .overview-grp {
      padding-top: 55px; } }
  .overview-grp h2 {
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 500; }
    @media (max-width: 750px) {
      .overview-grp h2 {
        font-size: 2.1rem;
        line-height: 2.6rem; } }
  .overview-grp h3 {
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin: 15px 0 50px; }
    @media (max-width: 750px) {
      .overview-grp h3 {
        font-size: 1.1rem;
        letter-spacing: .08rem;
        margin: 10px 0 15px; } }
  .overview-grp .item-box {
    display: flex; }
    @media (max-width: 750px) {
      .overview-grp .item-box {
        display: block; } }
  .overview-grp .box {
    width: 50%;
    min-height: 380px;
    color: #fff;
    padding: 30px; }
    @media (max-width: 750px) {
      .overview-grp .box {
        width: 100%;
        min-height: 0;
        height: 185px;
        padding: 10px; } }
    .overview-grp .box__txt {
      background-color: rgba(40, 55, 70, 0.4);
      height: 100%;
      padding-top: 75px;
      position: relative; }
      @media (max-width: 750px) {
        .overview-grp .box__txt {
          padding-top: 25px; } }
    .overview-grp .box__title {
      font-size: 3.6rem;
      line-height: 4.2rem; }
      @media (max-width: 750px) {
        .overview-grp .box__title {
          font-size: 2.1rem;
          line-height: 2.1rem; } }
    .overview-grp .box__copy {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 15px 0 38px; }
      @media (max-width: 750px) {
        .overview-grp .box__copy {
          font-size: 1.2rem;
          line-height: 1.6rem;
          padding: 10px 0 20px; } }
    .overview-grp .box .border-btn {
      width: 180px;
      height: 50px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 50px; }
      @media (max-width: 750px) {
        .overview-grp .box .border-btn {
          width: 140px;
          height: 40px;
          bottom: 20px; } }
      .overview-grp .box .border-btn p {
        font-size: 1.4rem; }
        @media (max-width: 750px) {
          .overview-grp .box .border-btn p {
            font-size: 1.2rem; } }
  .overview-grp .box1 {
    background: url(images/top/top_concept-bg.jpg) center 0 no-repeat;
    background-size: cover; }
  .overview-grp .box2 {
    background: url(images/top/top_catalog-bg.jpg) center 0 no-repeat;
    background-size: cover; }
  .overview-grp .company__list {
    background-color: #f0f2f6;
    display: flex;
    justify-content: space-between;
    padding: 30px; }
    @media (max-width: 750px) {
      .overview-grp .company__list {
        display: block;
        padding: 20px; } }
  .overview-grp .company__box {
    width: 32%;
    padding-bottom: 16px; }
    @media (max-width: 750px) {
      .overview-grp .company__box {
        width: 100%; } }
  .overview-grp .company__logo {
    width: 100%;
    padding: 30px 0;
    background-color: #fff;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #d2dce6;
    display: block; }
    .overview-grp .company__logo:hover img {
      opacity: .5; }
    @media (max-width: 750px) {
      .overview-grp .company__logo {
        padding: 15px 0; } }
    .overview-grp .company__logo img {
      height: 40px;
      transition: .3s ease; }
      @media (max-width: 750px) {
        .overview-grp .company__logo img {
          height: 30px; } }
  .overview-grp .company__title {
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 500;
    padding-top: 15px; }
    @media (max-width: 750px) {
      .overview-grp .company__title {
        font-size: 1.6rem;
        line-height: 2.1rem;
        padding-top: 10px; } }
  .overview-grp .company__copy {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding-top: 5px; }
    @media (max-width: 750px) {
      .overview-grp .company__copy {
        font-size: 1.2rem; } }
  .overview-grp .kuroko {
    background-color: #fff;
    padding: 30px;
    height: 400px; }
    @media (max-width: 750px) {
      .overview-grp .kuroko {
        height: auto;
        padding: 20px; } }
    .overview-grp .kuroko__box {
      width: 100%;
      height: 100%;
      background-color: #f5f0eb;
      padding-top: 65px;
      padding-left: calc(100% - 400px);
      position: relative; }
      @media (max-width: 750px) {
        .overview-grp .kuroko__box {
          padding: 0; } }
      .overview-grp .kuroko__box:before {
        content: '';
        display: block;
        width: calc(100% - 400px);
        height: 100%;
        background: url(images/top/top_kuroko-bg.jpg) 40% 50% no-repeat;
        position: absolute;
        left: 0;
        top: 0; }
        @media (max-width: 750px) {
          .overview-grp .kuroko__box:before {
            position: static;
            height: 180px;
            width: auto;
            background-size: cover;
            background-position: top center; } }
      .overview-grp .kuroko__box .border-btn {
        border-color: #000;
        width: 180px;
        height: 50px; }
        @media (max-width: 750px) {
          .overview-grp .kuroko__box .border-btn {
            width: 140px;
            height: 40px;
            margin: 0 auto; } }
        .overview-grp .kuroko__box .border-btn a:hover {
          background-color: #2b2b2b; }
          .overview-grp .kuroko__box .border-btn a:hover p {
            color: #f5f0eb; }
        .overview-grp .kuroko__box .border-btn p {
          color: #000;
          font-size: 1.4rem; }
          @media (max-width: 750px) {
            .overview-grp .kuroko__box .border-btn p {
              font-size: 1.2rem; } }
    @media (max-width: 750px) {
      .overview-grp .kuroko__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0; } }
    .overview-grp .kuroko__logo {
      max-width: 340px;
      margin: 0 auto; }
      .overview-grp .kuroko__logo a:hover {
        opacity: .7; }
      @media (max-width: 750px) {
        .overview-grp .kuroko__logo {
          padding: 0 20px 15px 20px;
          width: 100%;
          max-width: 380px; } }
    .overview-grp .kuroko__title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 30px 0 22px; }
      @media (max-width: 750px) {
        .overview-grp .kuroko__title {
          font-size: 1.2rem;
          margin: 0 0 10px 0; } }

.recruit-grp {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-align: center;
  transform-origin: center center -50vw;
  transition: all 1s ease 0s;
  transform: matrix3d(0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, -100, 1);
  z-index: 10; }
  @media (max-width: 750px) {
    .recruit-grp {
      padding-top: 55px; } }
  .recruit-grp h2 {
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 500; }
    @media (max-width: 750px) {
      .recruit-grp h2 {
        font-size: 2.1rem;
        line-height: 2.6rem; } }
  .recruit-grp h3 {
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin: 15px 0 50px; }
    @media (max-width: 750px) {
      .recruit-grp h3 {
        font-size: 1.1rem;
        line-height: 2rem;
        margin: 10px 0 15px; } }
  .recruit-grp .item-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
    background-color: #f0f2f6; }
    @media (max-width: 750px) {
      .recruit-grp .item-box {
        padding-bottom: 0; } }
  .recruit-grp .box {
    width: 46.5%;
    min-height: 380px;
    color: #fff;
    padding: 30px; }
    @media (max-width: 750px) {
      .recruit-grp .box {
        width: 100%;
        min-height: 185px;
        margin-bottom: 0;
        padding: 15px; } }
    .recruit-grp .box__txt {
      background-color: rgba(40, 55, 70, 0.35);
      height: 100%;
      padding-top: 90px; }
      @media (max-width: 750px) {
        .recruit-grp .box__txt {
          padding: 30px 0 20px; } }
    .recruit-grp .box__title {
      font-size: 3.6rem;
      line-height: 4.2rem; }
      @media (max-width: 750px) {
        .recruit-grp .box__title {
          font-size: 2.1rem;
          line-height: 2.1rem; } }
    .recruit-grp .box__copy {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 15px 0 38px; }
      @media (max-width: 750px) {
        .recruit-grp .box__copy {
          font-size: 1.2rem;
          line-height: 2.1rem;
          padding: 15px 0 18px; } }
    .recruit-grp .box .border-btn {
      width: 180px;
      height: 50px; }
      @media (max-width: 750px) {
        .recruit-grp .box .border-btn {
          width: 140px;
          height: 40px; } }
      .recruit-grp .box .border-btn p {
        font-size: 1.4rem; }
        @media (max-width: 750px) {
          .recruit-grp .box .border-btn p {
            font-size: 1.2rem; } }
  .recruit-grp .box1 {
    width: 100%;
    min-height: 520px;
    background: url(images/top/top_recruit-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-bottom: 30px; }
    @media (max-width: 750px) {
      .recruit-grp .box1 {
        margin-bottom: 0;
        min-height: 185px; } }
    .recruit-grp .box1 .box__title {
      font-size: 4.6rem;
      line-height: 4.2rem;
      padding-top: 50px; }
      @media (max-width: 750px) {
        .recruit-grp .box1 .box__title {
          font-size: 2.1rem;
          line-height: 2.1rem;
          padding-top: 0; } }
    .recruit-grp .box1 .box__copy {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding: 15px 0 38px; }
      @media (max-width: 750px) {
        .recruit-grp .box1 .box__copy {
          font-size: 1.2rem;
          line-height: 2.1rem;
          padding: 15px 0 18px; } }
    .recruit-grp .box1 .border-btn {
      width: 240px;
      height: 60px; }
      @media (max-width: 750px) {
        .recruit-grp .box1 .border-btn {
          width: 140px;
          height: 40px; } }
      .recruit-grp .box1 .border-btn p {
        font-style: 1.6rem; }
        @media (max-width: 750px) {
          .recruit-grp .box1 .border-btn p {
            font-size: 1.2rem; } }
  .recruit-grp .box2 {
    background: url(images/top/top_guidelines-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-left: 30px; }
    @media (max-width: 750px) {
      .recruit-grp .box2 {
        margin-left: 0; } }
  .recruit-grp .box3 {
    background: url(images/top/top_application-bg.jpg) center 0 no-repeat;
    background-size: cover;
    margin-right: 30px; }
    @media (max-width: 750px) {
      .recruit-grp .box3 {
        margin-right: 0; } }

.active-left .overview-grp {
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, -100, 1);
  position: static; }

.active-left .recruit-grp {
  transform: matrix3d(0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, -100, 1);
  position: absolute; }

.active-right .overview-grp {
  transform: matrix3d(0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, -100, 1);
  position: absolute; }

.active-right .recruit-grp {
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, -100, 1);
  position: static; }
